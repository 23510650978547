import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/Slices/modalSlice';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import './scss/workspace.scss';
import { find } from '../../utils/helpers';
import { config } from '../../config';

const WorkspaceSidebar = ({ openWorkspaces, toggleOptions, handleCreateWorkspace }) => {
    const { workspaces } = useSelector(state => state.workspaces);
    const { userName } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchWorkspaces());
    }, [dispatch]);

    return (
        <aside className="zoobbe-sidebar">
            <nav className="zoobbe-sidebar-nav">
                <ul className='quick-links'>
                    <li className="zoobbe-nav-item"><Link to={'#'} >Boards</Link></li>
                    <li className="zoobbe-nav-item"><Link to={'#'} >Templates</Link></li>
                    <li className="zoobbe-nav-item"><Link to={'#'} >Home</Link></li>
                </ul>
                <div className="zoobbe-workspaces">
                    <h3 onClick={handleCreateWorkspace}>Workspaces
                        <span>+</span>
                    </h3>
                    {workspaces.map(workspace => {
                        // setWorkspaceId(workspace._id);

                        return (
                            <div key={workspace._id} className="zoobbe-workspace">
                                <div className="zoobbe-workspace-header" onClick={() => toggleOptions(workspace.name)}>
                                    <div className="workspace-name">
                                        <span className="zoobbe-workspace-icon">{workspace.name ? workspace.name.charAt(0) : ''}</span>
                                        <span className="zoobbe-workspace-title">{workspace.name}</span>
                                    </div>
                                    <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.2929 16.7071L4.22185 9.63606C3.83132 9.24554 3.83132 8.61237 4.22185 8.22185C4.61237 7.83133 5.24554 7.83133 5.63606 8.22185L12 14.5858L18.364 8.22185C18.7545 7.83132 19.3877 7.83132 19.7782 8.22185C20.1687 8.61237 20.1687 9.24554 19.7782 9.63606L12.7071 16.7071C12.3166 17.0977 11.6834 17.0977 11.2929 16.7071Z" fill="currentColor"></path>
                                    </svg>
                                </div>
                                {openWorkspaces[workspace.name] && (
                                    <ul className="zoobbe-workspace-options">
                                        <li><Link to={`/w/${workspace.shortId}/boards`}>Boards</Link></li>
                                        {/* <li><Link to={`/w/${workspace.shortName}/views`}>Views</Link></li> */}
                                        <li><Link to={`/w/${workspace.shortId}/members`}>Members</Link></li>
                                        <li><Link to={`/w/${workspace.shortId}/settings`}>Settings</Link></li>
                                    </ul>
                                )}
                            </div>
                        )
                    })}
                </div>
            </nav>
        </aside>
    );
};

export default WorkspaceSidebar;
