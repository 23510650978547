// redux/Slices/thunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUsersStart, fetchUsersSuccess, fetchUsersFailure } from './usersSlice';
import { fetchUserStart, fetchUserSuccess, fetchUserFailure } from './userSlice';
import { getUsers, getUser } from '../../utils/helpers';

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async (_, { dispatch }) => {
        dispatch(fetchUsersStart());
        try {
            const data = await getUsers();
            if (data) {
                dispatch(fetchUsersSuccess(data));
            } else {
                dispatch(fetchUsersFailure('No data returned'));
            }
        } catch (error) {
            dispatch(fetchUsersFailure(error.toString()));
        }
    }
);

export const fetchUser = createAsyncThunk(
    'users/fetchUser',
    async (_, { dispatch }) => {
        dispatch(fetchUserStart());
        try {
            const data = await getUser();
            if (data) {
                dispatch(fetchUserSuccess(data));
            } else {
                dispatch(fetchUserFailure('No data returned'));
            }
        } catch (error) {
            dispatch(fetchUserFailure(error.toString()));
        }
    }
);
