import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { config } from '../../../src/config';

import './index.scss'; // Make sure to create and import the corresponding CSS file

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });
  const [message, setMessage] = useState('');
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('click login');

    setSubmit(true)

    try {
      const response = await fetch(config.API_URI + '/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData), // Send email and password as JSON
      });
      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      console.log('Login successful:', data);
      localStorage.setItem('accessToken', data.accessToken);
      setMessage('Login successful');
      setIsLoginSuccess(true);
      // Redirect to the workspace page

      // Fetch the username using another request
      const userResponse = await fetch(config.API_URI + '/api/users/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.accessToken}`,
        },
      });

      if (!userResponse.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await userResponse.json();
      const username = userData.user.username;

      console.log(username);

      setTimeout(() => {
        window.location.href = `/u/${username}/boards`;
      }, 2000);

    } catch (error) {
      console.error('Error:', error);
      setMessage('Login failed. Invalid credentials.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Login - Zoobbe</title>
      </Helmet>
      <div className="login-container">
        <div className="login-box">
          <h1 className="login-logo">Zoobbe</h1>
          <h2 className="login-heading">Log in to continue</h2>
          <form className="login-form" onSubmit={handleLogin}>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={loginData.email}
              onChange={handleChange}
              className="login-input"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={loginData.password}
              onChange={handleChange}
              className="login-input"
              required
            />
            <button type="submit" disabled={isSubmit} className="login-button">Continue</button>

            {message && <p className={isLoginSuccess ? 'success-message' : 'error-message'}>{message}</p>}

          </form>
          <p className="login-or">Or continue with:</p>
          <div className="login-social-buttons">
            <button className="social-button google">Google</button>
            <button className="social-button microsoft">Microsoft</button>
            <button className="social-button apple">Apple</button>
            <button className="social-button slack">Slack</button>
          </div>
          <div className="login-links">
            <Link to="#" className="login-link">Can't log in?</Link>
            <span> · </span>
            <Link to="/signup" className="login-link">Create an account</Link>
          </div>
          <footer className="login-footer">
            <p className="login-atlassian">Zoobbe, Inc.</p>
            <p className="login-footer-text">One account for Zoobbe, Jira, Confluence and more.</p>
            <div className="login-privacy">
              <Link to="#" className="login-privacy-link">Privacy Policy</Link>
              <span> · </span>
              <Link to="#" className="login-privacy-link">User Notice</Link>
            </div>
            <p className="login-footer-note">
              This site is protected by reCAPTCHA and the Google
              <Link to="#" className="login-privacy-link">Privacy Policy</Link> and
              <Link to="#" className="login-privacy-link">Terms of Service</Link> apply.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Login;
