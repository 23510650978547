// DynamicPopover.js
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ImagePlaceholder from './ImagePlaceholder';

import './scss/ProfileCard.scss';

const ProfileCard = ({ name, username, imageUrl, onEditProfile, onRemoveFromCard }) => {
    const [size, setSize] = useState(90);

    const { member } = useSelector((state) => state.member);

    if (!member) {
        return;
    }

    return (
        <div className="profile-card">
            <div className="profile-card__content">
                {
                    member?.profilePicture ? (
                        <img style={{ width: size, height: size }} key={member._id} src={member.profilePicture} alt={member.name} />
                    ) : (
                        <ImagePlaceholder key={member._id} size={size} text={member.username} />
                    )
                }
                <div className="profile-card__info">
                    <h2 className="profile-card__name">{member.name}</h2>
                    <p className="profile-card__username">@{member.username}</p>
                </div>
            </div>
            <div className="profile-card__actions">
                <button className="profile-card__action" onClick={onEditProfile}>
                    Edit profile info
                </button>
                <button className="profile-card__action" onClick={onRemoveFromCard}>
                    Remove from card
                </button>
            </div>
        </div>
    );
};


export default ProfileCard;
