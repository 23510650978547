import React, { useState, useEffect, useRef, act } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ZoobbeSelect from '../Global/ZoobbeSelect';

import './scss/MoveCard.scss';
import { fetchChecklists } from '../../redux/Slices/checklistSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { config } from '../../config';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import { fetchBoards } from '../../redux/Slices/boardsSlice';
import { fetchWorkspaces, workspaceSlice } from '../../redux/Slices/workspaceSlice';
import ZoobbeSelectExtend from '../Global/ZoobbeSelectExtend';
import SearchSelect from '../Global/SearchSelect';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';


const MoveCard = () => {
    const boardId = useSelector(selectBoardId);
    const { boards, status } = useSelector(state => state.boards);
    const { card } = useSelector(state => state.card);
    const cardId = card.shortId;


    const [title, setTitle] = useState('Checklist');
    const [actionLists, setActionLists] = useState([]);
    const [cards, setCards] = useState([cardId]);
    const [selectedBoard, setSelectedBoard] = useState(boardId || null); // Track selected board
    const [selectedList, setSelectedList] = useState(card.actionList || null); // Track selected board
    const [selectedPosition, setPosition] = useState(card.shortId || null); // Track selected board



    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBoards());
    }, [dispatch]);

    useEffect(() => {
        if (selectedBoard) {
            const board = boards.find(board => board.shortId === selectedBoard);

            if (board && board.actionLists) {
                setActionLists(board.actionLists);
            } else {
                setActionLists([]);
            }
        }
    }, [selectedBoard, boards]);

    useEffect(() => {
        if (selectedList) {
            const actionList = actionLists.find(actionList => actionList._id === selectedList);
            if (actionList && actionList.cards) {
                setCards((prev) => [...actionList.cards, prev]);
            }
        }
    }, [selectedList, actionLists]);

    // Filter boards to exclude those with empty actionLists
    const boardOptions = boards
        ?.filter(board => board.actionLists && board.actionLists.length > 0)
        .map(board => {
            let label = board.title;
            if (board.shortId === boardId) {
                label = `${label}<br><span className="current-option">(current)</span>`;
            }
            return ({
                value: board.shortId,
                label: label,
                workspaceShortId: board.workspaceShortId,
                workspaceName: board.workspaceName
            })
        }) || [];

    const actionListOptions = actionLists?.map(actionList => {
        let title = actionList.title;
        if (actionList._id.toString() === card.actionList) {
            title = `${title}<br><span className="current-option">(current)</span>`; // Adding newline before (current)
        }
        return ({
            value: actionList._id.toString(),
            label: title,
        });
    }) || [];


    console.log({ actionListOptions });

    const positionOptions = cards && cards?.map((card, index) => {

        let label = (index + 1).toString();

        if (cardId === card.shortId) {
            label = `${label}<br><span className="current-option">(current)</span>`;
        }

        return ({
            value: card.shortId,
            label: label,
        })

    }) || [{ value: card.shortId, label: '1' }];


    // Handle board selection and update action lists accordingly
    const handleBoardSelect = (selectedBoardOption) => {
        const board = boards.find(board => board.shortId === selectedBoardOption.value);

        if (board && board.actionLists) {
            setActionLists(board.actionLists); // Update action lists for the selected board
            setSelectedBoard(selectedBoardOption.value); // Update selected board
            setSelectedList(null); // Reset the selected action list
        }

    };

    // Handle action list selection
    const handleActionListSelect = (selectedListOption) => {

        setSelectedList(selectedListOption.value);

        const actionList = actionLists.find(actionList => actionList._id === selectedListOption.value);

        if (actionList.cards.length > 0) {
            setPosition(actionList.cards[actionList.cards.length - 1].shortId);
        }
        else {
            setPosition(card.shortId);
        }

    };


    // Handle action list selection
    const handlePositionSelect = (selectedPosition) => {
        setPosition(selectedPosition.value);
    };



    // Handle card move action
    const handleMoveCard = async () => {
        if (!title) {
            alert("Please enter a title for the checklist");
            return;
        }

        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title })
            });

            if (response.ok) {
                const updatedCard = await response.json();
                dispatch(fetchChecklists(cardId));
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
                setTitle('Checklist');
                dispatch(fetchActivities({cardId}));
            } else {
                console.error('Failed to add checklist');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="move-card">
            <h2>Move Card</h2>

            <div className="group">
                <h3>Select Destination</h3>
                <SearchSelect
                    options={boardOptions}
                    onSelect={handleBoardSelect}
                    placeholder="Select an option"
                    isGrouped={true}
                    defaultValue={selectedBoard}
                />
            </div>
            <div className='actionlists-group'>
                <div className="group actionlists">
                    <h3>List</h3>
                    <SearchSelect
                        options={actionListOptions}
                        onSelect={handleActionListSelect}
                        placeholder="Select a list"
                        isGrouped={false}
                        defaultValue={selectedList}
                    />
                </div>
                <div className="group position">
                    <h3>Possition</h3>
                    <SearchSelect
                        options={positionOptions}
                        onSelect={handlePositionSelect}
                        placeholder="Select an option"
                        isGrouped={false}
                        defaultValue={selectedPosition}

                    />
                </div>
            </div>
            <button onClick={handleMoveCard}>Move</button>
        </div>
    );
};

export default MoveCard;
