import react, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCardMembers } from '../../redux/Slices/cardMemberSlice';

import MembersPopover from './MembersPopover';

import MemberImage from '../Global/MemberImage';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { find } from '../../utils/helpers';
import { fetchMembers } from '../../redux/Slices/memberSlice';


const CardMembers = ({ cardId }) => {

    const dispatch = useDispatch();

    const { handlePopoverClick } = useHandlePopoverClick();


    const { cardMembers } = useSelector(state => state.member) || [];

    console.log({ cardMembers })


    useEffect(() => {
        dispatch(fetchMembers({ type: 'card', id: cardId }));
    }, [cardId, dispatch]);


    return (
        <>
            {
                cardMembers?.length > 0 && (

                    <div className="zoobbe-card-members">
                        <h5>Members</h5>
                        <div className='member-list'>

                            <div className="avatars">
                                <MemberImage members={cardMembers} size={35} />
                            </div>

                            <div className='add-member-to-card' onClick={(e) => handlePopoverClick(e, 'cardMemberesPopover')}>
                                <span className="material-symbols-outlined">
                                    add
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}


export default CardMembers;