import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { uploadAttachment } from "../../redux/Slices/attachmentSlice";

const Attachment = ({ onUpload }) => (
    <span className="material-symbols-outlined">
        attachment
    </span>
);

const EditorToolbar = ({ cardId }) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            dispatch(uploadAttachment({ file, cardId }))
                .then((result) => {
                    console.log(result);

                    // Assuming result contains a payload and requestStatus
                    if (result.meta.requestStatus === 'fulfilled') {
                        const { name, url } = result.payload[result.payload.length - 1];

                        if (window.quillRef && url) {
                            const quill = window.quillRef.getEditor();
                            const range = quill.getSelection();

                            console.log(range);

                            if (range) {
                                quill.insertText(range.index, name, "link", url);
                                quill.insertText(range.index + name.length, " ", "link", url); // Add a space after the link
                            }
                        }
                    } else {
                        console.error('File upload failed or incomplete');
                    }
                })
                .catch((error) => {
                    console.error('Error uploading file:', error);
                });
        }
    };





    return (
        <div id="toolbar">
            <span className="ql-formats">
                <select className="ql-header" defaultValue="3">
                    <option value="1">Heading</option>
                    <option value="2">Subheading</option>
                    <option value="3">Normal</option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-strike" />
            </span>
            <span className="ql-formats">
                <button className="ql-blockquote" />
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
            </span>
            <span className="ql-formats">
                <button className="ql-link" />
                <button className="ql-image" />
                <button className="ql-code-block" />
            </span>
            <span className="ql-formats">
                <button className="ql-attachment" onClick={handleAttachmentClick}>
                    <Attachment />
                </button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </span>
        </div>
    );
};

export default EditorToolbar;
