import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/Slices/modalSlice';

import CreateWorkspace from './CreateWorkspace';


import './scss/index.scss'; // Assuming you have a CSS file for styles
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import ImagePlaceholder from '../Global/ImagePlaceholder';

const ProfileDropdown = () => {

    const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);


    const dispatch = useDispatch();

    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
    };

    return (
        <div className="zoobbe-profile">
            <div className="account-section">
                {user?.user?.profilePicture ? (
                    <img
                        className='profile-picture'
                        src={user?.user?.profilePicture}
                        alt={user?.user?.username}
                    />
                ) : (
                    <ImagePlaceholder
                        key={user?.user?._id}
                        size={50}
                        text={user?.user?.username}
                    />

                )}
                <div className="account-info">
                    <p className="username">{user.user.username}</p>
                    <p className="email">{user.user.email}</p>
                </div>
            </div>
            <div className="manage-account-section">
                <a href="#" className="manage-account">Manage account</a>

            </div>
            <div className="menu-section">
                <p className="menu-title">Zoobbe</p>
                <ul>
                    <li><Link to={`/u/${user.user.username}/profile`}>Profile and visibility</Link></li>
                    <li><Link to={`/u/${user.user.username}/activity`}>Activity</Link></li>
                    <li><Link to={`/u/${user.user.username}/cards`}>Cards</Link></li>
                    <li><Link to={`/u/${user.user.username}/settings`}>Settings</Link></li>
                    <li><Link to="#">Theme</Link></li>
                </ul>
            </div>
            <div className="workspace-section">
                <button className="create-workspace" onClick={handleCreateWorkspace}>
                    <span className="material-symbols-outlined">
                        workspaces
                    </span>
                    Create Workspace
                </button>
            </div>
            <div className="other-section">
                <ul>
                    <li><Link to="#">Help</Link></li>
                    <li><Link to="#">Shortcuts</Link></li>
                </ul>
            </div>

            <div className="workspace-section">
                <Link className="export-import-board" to={'/import-export'} >
                    <span className="material-icons">
                        import_export
                    </span>
                    Import / Export
                </Link>
            </div>
            <div className="logout-section">
                <ul>
                    <li><Link to="#">
                        <span className="material-symbols-outlined">
                            logout
                        </span>
                        Log out</Link>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default ProfileDropdown;
