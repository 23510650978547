import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCardLabels, fetchLabels } from '../../redux/Slices/labelSlice';
import './scss/CardLabels.scss';
import { togglePopover } from '../../redux/Slices/popoverSlice';

const CardLabels = ({ cardId }) => {
    const dispatch = useDispatch();
    const { labelsByCard, status, error } = useSelector((state) => state.labels);
    const labels = labelsByCard[cardId] || [];


    useEffect(() => {
        if (cardId && labels.length === 0) {
            dispatch(fetchCardLabels(cardId));
        }
    }, [cardId, labels.length, dispatch]);



    const [popoverState, setPopoverState] = useState({ isVisible: false, contentId: null });

    const handlePopoverClick = (e, contentId) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };

        if (popoverState.isVisible && popoverState.contentId === contentId) {
            setPopoverState({ isVisible: false, contentId: null });
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
        } else {
            setPopoverState({ isVisible: true, contentId });
            dispatch(togglePopover({ contentId, position, target }));
        }
    };

    return (
        <>
            {labels.length > 0 && (
                <div className="zoobbe-labels">
                    <h5>Labels</h5>
                    <div className='label-list-container'>
                        <div className='label-list'>
                            {labels.map(label => (
                                <span key={label._id} style={{ backgroundColor: label.color }} className="zoobbe-label zoobbe-bug-fix" onClick={(e) => handlePopoverClick(e, 'addLabels')}>
                                    {label.text}
                                </span>
                            ))}
                            <div className="add-labels-to-card" onClick={(e) => handlePopoverClick(e, 'addLabels')}>
                                <span className="material-symbols-outlined">add</span>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </>
    );
};

export default CardLabels;
