import React, { createContext, useState, useContext } from 'react';

const BoardContext = createContext();

export const BoardProvider = ({ children }) => {
    const [boardPermalink, setBoardPermalink] = useState('');

    return (
        <BoardContext.Provider value={{ boardPermalink, setBoardPermalink }}>
            {children}
        </BoardContext.Provider>
    );
};

export const useBoard = () => useContext(BoardContext);
