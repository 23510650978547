import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '../../config';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { showToast } from '../../redux/Slices/toastSlice';

import './scss/ShareCard.scss';


const ShareCard = () => {
    const inputRef = useRef(null);
    const { card } = useSelector((state) => state.card);
    const cardId = card.shortId;

    const dispatch = useDispatch();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, []);

    const handleFocus = () => {
        if (inputRef.current) {
            inputRef.current.select(); // Select text on focus

            // Use the modern Clipboard API to copy the text
            navigator.clipboard.writeText(inputRef.current.value)
                .then(() => {
                    dispatch(showToast({ message: 'Link copied!', type: 'success' }));
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        }
    };

    return (
        <div className="share-card">
            <h2>Share</h2>
            <div className="group">
                <h3>Share the link</h3>
                <input
                    type="text"
                    placeholder="Checklist"
                    value={config.DOMAIN + card.shortLink}
                    ref={inputRef}
                    onFocus={handleFocus}
                    readOnly

                />
            </div>
        </div>
    );
};

export default ShareCard;
