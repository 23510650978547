import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { config } from '../../config';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { showToast } from '../../redux/Slices/toastSlice';

import './scss/DeleteCard.scss';


const DeleteCard = () => {
    const inputRef = useRef(null);
    const { card } = useSelector((state) => state.card);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDeleteCard = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/delete/${card.shortId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the card');
            }

            const data = await response.json();
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
            navigate(card.boardLink);

            console.log('Card deleted successfully:', data);
        } catch (error) {
            console.error('Error deleting the card:', error);
        }
    };

    return (
        <div className="delete-card">
            <h2>Delete?</h2>

            <div className="group">
                <p className='warning-message'>Deleting this card will remove all actions from the activity feed, and you cannot re-open it. This action cannot be undone.</p>
                <button onClick={() => handleDeleteCard()}>Delete</button>
            </div>
        </div>
    );
};

export default DeleteCard;
