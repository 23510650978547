import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import Checkbox from '../Global/Checkbox';
import { config } from '../../config';


import './scss/Notifications.scss';
import ImagePlaceholder from '../Global/ImagePlaceholder';

// Initialize Socket.IO
const socket = io(config.API_URI);  // Ensure config.API_URI matches your backend server URL

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    const { user } = useSelector((state) => state.user);
    console.log(notifications);


    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(`${config.API_URI}/api/notifications`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setNotifications(data);
                } else {
                    console.error('Failed to fetch notifications');
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();

        // Join the user-specific room for real-time notifications
        if (user) {
            const userId = user.user._id;
            socket.emit('join', userId);

            // Listen for new notifications
            socket.on('newNotification', (notification) => {
                console.log('New notification', notification);

                setNotifications((prevNotifications) => [notification, ...prevNotifications]);
            });

            // Cleanup on component unmount
            return () => {
                socket.off('newNotification');
            };
        }
    }, [user]);

    const handleMarkAllAsRead = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            await fetch(`${config.API_URI}/api/notifications/mark-all-read`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            // Update the notifications state to mark all as read
            setNotifications(notifications.map(notification => ({ ...notification, read: true })));
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    const filteredNotifications = isChecked
        ? notifications.filter(notification => !notification.read)
        : notifications;

    return (
        <div className="notifications-page">
            <div className="notifications-panel">
                <div className="header">
                    <div className="actions">
                        <h2>Notifications</h2>
                        <span className='show-unread-checkbox'>
                            Only show unread
                            <Checkbox
                                checked={isChecked}
                                onChange={(checked) => setIsChecked(checked)}
                            />
                        </span>
                    </div>
                    <button onClick={handleMarkAllAsRead}>Mark all as read</button>
                </div>
                <ul className="notification-list">
                    {filteredNotifications.map((notification) => {
                        if (!notification.initiator) {
                            return;
                        }
                        return (
                            <li
                                key={notification._id}
                                className={`notification-item ${!notification.read ? 'unread' : ''}`}
                            >
                                <div className="user-avatar">
                                    {notification.initiator.profilePicture ? (
                                        <img src={notification.initiator.profilePicture} alt={notification.initiator.name} style={{ width: "35px", height: "35px", borderRadius: "50%" }} />
                                    ) : (
                                        <ImagePlaceholder key={notification.initiator._id} size={35} text={notification.initiator.username} />
                                    )}
                                </div>
                                <div className="notification-content">
                                    <a className='initiator' href='#'>{notification.initiator.username}</a><br />
                                    <span className='content' dangerouslySetInnerHTML={{ __html: notification.message }}></span>
                                    {notification.targetType && (
                                        <a className={'target-type'} href="#">{notification.targetType}</a>
                                    )}
                                    <span className="notification-date">{new Date(notification.createdAt).toLocaleString()}</span>
                                </div>
                                {!notification.read && <span className="unread-indicator"></span>}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Notifications;
