import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { showToast } from '../../redux/Slices/toastSlice';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchBoardMembers } from '../../redux/Slices/boardMembersSlice';
import ZoobbeSelect from '../Global/ZoobbeSelect';

import { togglePopover } from '../../redux/Slices/popoverSlice';
import { fetchMembers, memberInfo } from '../../redux/Slices/memberSlice';
import { config } from '../../config';

import { fetchUsers } from '../../redux/Slices/thunks';

import ImagePlaceholder from '../Global/ImagePlaceholder';
import { find, getOptionIndex } from '../../utils/helpers';

import './scss/ShareBoard.scss';
import '../Global/scss/MembersSearchResult.scss';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import useOutsideClick from '../../hooks/useOutsideClick';

const ShareBoardModal = ({ workspaceSlug }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [message, setMessage] = useState("Join me on Zoobbe and let's work together on this board!");
    const [activeTab, setActiveTab] = useState('members');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedRole, setSelectedRole] = useState({ value: 'member', label: 'Member' });
    const [popoverState, setPopoverState] = useState({ isVisible: false, contentId: null });
    const [size] = useState(35);
    const [isFocused, setIsFocused] = useState(false);

    const emailRef = useRef();

    const { users } = useSelector((state) => state.users); // Added currentUser here
    const { user } = useSelector((state) => state.user);

    const { workspaces } = useSelector((state) => state.workspaces);
    const boardId = useSelector(selectBoardId);

    const workspace = find.get(workspaces, { name: 'workspace', shortName: workspaceSlug }) || '';
    const workspaceId = workspace?._id;
    const collaborators = useSelector(state => state.member.boardMembers);

    const totalAdmins = collaborators.filter(m => m.role === 'admin').length;

    console.log({ boardId });

    useEffect(() => {
        if (!users) {
            dispatch(fetchUsers());
        }
    }, [users, dispatch]);

    useEffect(() => {
        if (boardId) {
            dispatch(fetchMembers({ type: 'board', id: boardId }));
        }
    }, [boardId, dispatch]);

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (value?.length > 0) {
            const results = users?.filter((item) =>
                item.name.toLowerCase().includes(value.toLowerCase()) ||
                item.email.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    };

    const removeUser = (index) => {
        setSelectedUsers((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSelectUser = (user) => {
        if (!selectedUsers.some((selected) => selected._id === user._id)) {
            setSelectedUsers([...selectedUsers, user]);
            setEmail('');
            setSearchResults([]);
        }
    };

    const addMemberToBoard = async (e) => {
        e.preventDefault();

        if (!boardId) {
            dispatch(showToast({ message: 'Board Id Empty', type: 'error' }));
            return;
        }

        try {
            const token = localStorage.getItem('accessToken');

            // Prepare the members array with both memberId and role
            const members = selectedUsers.map((user) => ({
                memberId: user._id,
                role: selectedRole.value || 'member', // Assuming selectedRole is an object with userId as the key
            }));

            const response = await fetch(config.API_URI + `/api/boards/${boardId}/add-member`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    boardId: boardId,
                    members: members,
                }),
            });

            const data = await response.json();
            const messageType = response.status === 200 ? 'success' : 'error';

            dispatch(showToast({ message: data.message, type: messageType }));
            if (response.status === 200) {
                dispatch(fetchMembers({ type: 'board', id: boardId }));
            }
        } catch (error) {
            console.error('Error adding member:', error);
            dispatch(showToast({ message: 'Error adding member', type: 'error' }));
        }
    };

    const handleRoleSelect = (role) => {
        setSelectedRole(role);
    };

    const handleMemberInfo = (e, contentId, member) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };

        if (popoverState.isVisible && popoverState.contentId === contentId) {
            setPopoverState({ isVisible: false, contentId: null });
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
        } else {
            setPopoverState({ isVisible: true, contentId });
            dispatch(togglePopover({ contentId, position, target }));
            dispatch(memberInfo({ member }));
        }
    };

    const roleOptions = [
        { value: 'member', label: 'Member' },
        { value: 'ovserber', label: 'Ovserber' },
        { value: 'guest', label: 'Guest' },
    ];

    const handleFocus = () => {
        setIsFocused(true); // Set focus to true
    };

    useOutsideClick(emailRef, () => setIsFocused(false));

    return (
        <div className="sbm-share-board-modal">
            <div className="sbm-header">
                <h3>Share Board</h3>
            </div>
            <form onSubmit={addMemberToBoard}>
                <div className="sbm-input-group">
                    <div
                        className="sbm-email-input"
                        ref={emailRef}
                        style={{
                            outline: isFocused ? '2px solid #0966ff' : '1px solid #333',
                        }}
                    >
                        {selectedUsers.map((selectedUser, index) => (
                            <div key={index} className="sbm-selected-email">
                                {`@${selectedUser.username}`}
                                <button type="button" onClick={() => removeUser(index)}>
                                    <span className="material-icons">
                                        close
                                    </span>
                                </button>
                            </div>
                        ))}
                        <input
                            type="text"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Type name or email"
                            autoComplete="off"
                            onFocus={handleFocus}
                        />
                        {searchResults.length > 0 && (
                            <div className="sbm-search-results">
                                {searchResults.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className="mention-suggestion"
                                        onClick={() => handleSelectUser(suggestion)}
                                    >
                                        {suggestion.profilePicture ? (
                                            <img src={suggestion.profilePicture} alt={suggestion.name} />
                                        ) : (
                                            <ImagePlaceholder key={suggestion._id} size={size} text={suggestion.username} />
                                        )}
                                        <div>
                                            <div className="suggession-name">{suggestion.name}</div>
                                            <div className="suggession-handler">{`@${suggestion.username}`}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <ZoobbeSelect
                        options={roleOptions}
                        defaultSelectedOption={0}
                        onSelect={handleRoleSelect}
                    />
                </div>
                <button type="submit" className="sbm-invite-button">Invite</button>
            </form>
            <textarea
                className="sbm-message-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <div className="sbm-invite-link">
                <button className="sbm-create-link-button">Create link</button>
            </div>
            <div className="sbm-tabs">
                <button
                    className={`sbm-tab ${activeTab === 'members' ? 'active' : ''}`}
                    onClick={() => setActiveTab('members')}
                >
                    Board members
                </button>
                <button
                    className={`sbm-tab ${activeTab === 'requests' ? 'active' : ''}`}
                    onClick={() => setActiveTab('requests')}
                >
                    Requests
                </button>
            </div>
            {activeTab === 'members' && (
                <div className="sbm-workspace-members">
                    {collaborators?.map((member) => {
                        const isCurrentUser = member._id === user.user?._id;

                        console.log(member._id, user._id)

                        const handleRoleChange = (newRole) => {
                            if (newRole.value === 'admin' && totalAdmins < 2 && member.role !== 'admin') {
                                dispatch(showToast({ message: 'Cannot assign more admins. At least one admin is required.', type: 'error' }));
                                return;
                            }
                            if (member.role === 'admin' && newRole.value !== 'admin' && totalAdmins <= 1) {
                                dispatch(showToast({ message: 'Cannot remove the last admin. At least one admin is required.', type: 'error' }));
                                return;
                            }
                            handleRoleSelect(newRole, member._id); // Call the role select handler
                        };

                        return (
                            <div className="sbm-member" key={member._id}>
                                <span className="avatar" onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}>
                                    {member.profilePicture ? (
                                        <img
                                            className="sbm-member-image"
                                            style={{ width: size, height: size }}
                                            src={member.profilePicture}
                                            alt={member.name}
                                        />
                                    ) : (
                                        <ImagePlaceholder size={size} text={member.username} />
                                    )}
                                </span>
                                <span className="sbm-member-name">
                                    {member.username} {isCurrentUser && '(you)'}
                                </span>
                                <ZoobbeSelect
                                    options={roleOptions}
                                    defaultSelectedOption={getOptionIndex(member.role, roleOptions)}
                                    onSelect={handleRoleChange}
                                    className={' admin-role'}
                                />
                            </div>
                        )
                    })}
                </div>
            )}
            {activeTab === 'requests' && (
                <div className="sbm-requests">No requests found</div>
            )}
        </div>
    );
};

export default ShareBoardModal;
