import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Asynchronous thunk to fetch card by ID
export const fetchCardById = createAsyncThunk(
    'card/fetchCardById',
    async (cardId, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/cards/${cardId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch card');
        }

        const data = await response.json();
        return data;
    }
);

const cardSlice = createSlice({
    name: 'card',
    initialState: {
        card: null,
        status: 'idle',
        error: null
    },
    reducers: {
        cardData: (state, action) => {
            state.card = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCardById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCardById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.card = action.payload;
            })
            .addCase(fetchCardById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { cardData } = cardSlice.actions;
export default cardSlice.reducer;
