import React from 'react';

const NotFound = () => (
    <>
        <div className='zoobbe-board-not-found'>
            <p>The link you entered does not look like a valid Trello link. If someone gave you this link, you may need to ask them to check that it's correct.
            </p>
        </div>

    </>
);




export default NotFound;