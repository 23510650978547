import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import useOutsideClick from '../../hooks/useOutsideClick';
import { getContentById } from '../../redux/ContentRegistry/popover';
import usePopoverPositionUpdater from '../../hooks/usePopoverPositionUpdater';
import './Popover.scss';

const PopOver = () => {
    const { isVisible, contentId, position, target, props } = useSelector((state) => state.popover);

    const dispatch = useDispatch();
    const popoverRef = useRef();

    useOutsideClick(popoverRef, () => {
        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
    });

    usePopoverPositionUpdater(target, popoverRef);

    if (!isVisible) {
        return null;
    }

    const content = getContentById(contentId, props);

    return (
        <div
            className="zoobbe-popover"
            style={{ top: position.top, left: position.left }}
            ref={popoverRef}
        >
            <div className="zoobbe-popover__header">
                <button
                    className="zoobbe-popover__close-btn"
                    onClick={() => {
                        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
                    }}
                >
                    <span className="material-icons icon-close"> close</span>
                </button>
            </div>
            <div className="zoobbe-popover__content">
                {content}
            </div>
        </div>
    );
};

export default PopOver;
