import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../redux/Slices/thunks';
import ProfileDropdown from '../Profile/ProfileDropdown';
import Notifications from '../Global/Notifications';
import useOutsideClick from '../../hooks/useOutsideClick';
import './index.scss'; // Assuming you have a CSS file for styles
import { config } from '../../config';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import { openModal } from '../../redux/Slices/modalSlice';

// Initialize socket outside of the component to prevent re-initialization on each render
const socket = io(config.API_URI);

const Header = () => {
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [isShowNotifications, setIsShowNotifications] = useState(false);
  const [newNotification, setNewNotification] = useState(0);

  const profileRef = useRef(null);
  const notificationsRef = useRef(null);

  const dispatch = useDispatch();
  const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!user) return;

    const userId = user.user._id;
    socket.emit('join', userId);

    const fetchNotificationCount = async () => {
      const count = await getNewNotificationsCount();
      if (count !== undefined) {
        setNewNotification(count);
      }
    };

    fetchNotificationCount();

    socket.on('newNotification', async (notification) => {
      console.log('New notification received:', notification);
      setNewNotification(prevCount => {
        const updatedCount = prevCount + 1;
        addNewNotificationsCount(updatedCount);
        return updatedCount;
      });
    });

    return () => {
      socket.off('newNotification');
    };
  }, [user]);

  const handleShowProfile = () => setIsShowProfile(prev => !prev);

  const handleShowNotifications = () => {
    setIsShowNotifications(prev => !prev);
    setNewNotification(0);
    addNewNotificationsCount(0);
  };

  const handleCreateWorkspace = () => {
    dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
  };


  useOutsideClick(notificationsRef, () => setIsShowNotifications(false));
  useOutsideClick(profileRef, () => setIsShowProfile(false));

  const addNewNotificationsCount = async (count) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${config.API_URI}/api/notifications/count`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ newNotificationsCount: count }),
      });

      if (!response.ok) throw new Error('Failed to add new notification count');
      const data = await response.json();
      console.log('Notification count updated successfully:', data);
    } catch (error) {
      console.error('Error adding notification count:', error);
    }
  };

  const getNewNotificationsCount = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${config.API_URI}/api/notifications/count`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch notification count');
      const data = await response.json();
      return data.newNotificationsCount;
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  if (userLoading) return null;//<div className='loading'>Loading...</div>;
  if (userError) return <div>Error: {userError}</div>;

  return (
    <header className="zoobbe-header">
      <div className="zoobbe-header__logo"><Link to="/">Zoobbe</Link></div>
      <div className="zoobbe-header__menu">
        <button to="/create" onClick={handleCreateWorkspace} className="zoobbe-header__menu-item zoobbe-header__menu-item--create">Create</button>
      </div>
      <div className="zoobbe-header__search-container">
        <span className="material-symbols-outlined">
          search
        </span>
        <input
          className="zoobbe-header__search-input"
          type="text"
          placeholder="Search"
        />
      </div>
      <div className="zoobbe-header__icons">
        <div className="notifications-container" ref={notificationsRef}>
          <div
            className={`notifications-icon${(isShowNotifications || newNotification) ? ' active' : ''}`}
            onClick={handleShowNotifications}
          >
            <span className="material-symbols-outlined">
              notifications
            </span>
            {newNotification > 0 && <span className='notification-counter'>{newNotification}</span>}
          </div>

          {isShowNotifications && (
            <Notifications setIsShowNotifications={setIsShowNotifications} />
          )}
        </div>

        <div className="profile-container" ref={profileRef}>
          <div className="profile-image" onClick={handleShowProfile}>

            {user?.user?.profilePicture ? (
              <img
                className={`zoobbe-header__icons-avatar${isShowProfile ? ' active' : ''}`}
                src={user?.user?.profilePicture}
                alt={user?.user?.username}
              />
            ) : (
              <ImagePlaceholder
                key={user?.user?._id}
                size={30}
                text={user?.user?.username}
                className={`${isShowProfile ? ' active' : ''}`}

              />

            )}
          </div>

          {isShowProfile && (
            <ProfileDropdown userInfo={user} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
