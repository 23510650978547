import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/AddCardLabels.scss';
import { fetchCardLabels, fetchLabels, addLabelOptimistically, removeLabelOptimistically, updateLabelOptimistically, updateLabelOnCard, toggleLabelOnCardOptimistically } from '../../redux/Slices/labelSlice';
import { config } from '../../config';
import Checkbox from '../Global/Checkbox';

const AddLabels = (props) => {
    // State Hooks
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentLabel, setCurrentLabel] = useState('');
    const [currentColor, setCurrentColor] = useState('');
    const [editLabel, setEditLabel] = useState(null);

    // Redux Selectors
    const { card } = useSelector((state) => state.card);
    const { labelsByCard, labelsByBoard } = useSelector((state) => state.labels);
    const cardLabels = labelsByCard[card.shortId] || [];
    const allLabels = labelsByBoard[card.board] || [];

    console.log({cardLabels});

    // Refs
    const titleInputRef = useRef(null);
    const dispatch = useDispatch();
    const boardId = card.board;
    const cardId = card.shortId;

    // Fetch Labels for the Board when component mounts
    useEffect(() => {
        if (!labelsByBoard[boardId]) {
            dispatch(fetchLabels(boardId));
        }
    }, [dispatch, boardId, labelsByBoard]);

    // Fetch Labels for the Card when the card ID changes
    useEffect(() => {
        dispatch(fetchCardLabels(cardId));
    }, [dispatch, cardId]);

    // Focus on the input when modal is opened
    useEffect(() => {
        if (isEditModalOpen && titleInputRef.current) titleInputRef.current.focus();
    }, [isEditModalOpen]);

    // Modal Handlers
    const openEditModal = (label = '', color = '#61bd4f') => {
        setCurrentLabel(label);
        setCurrentColor(color);
        setEditModalOpen(true);
    };

    const closeEditModal = () => setEditModalOpen(false);

    const createLabel = async () => {
        if (currentLabel.trim()) {
            const newLabel = {
                _id: Date.now().toString(), // temporary ID for optimistic UI
                text: currentLabel,
                color: currentColor,
            };

            // Optimistically update the state
            dispatch(addLabelOptimistically({ boardId, newLabel }));

            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(`${config.API_URI}/api/boards/${boardId}/labels`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(newLabel),
                });

                if (!response.ok) throw new Error('Failed to add label');
                const updatedCard = await response.json();
                dispatch(fetchLabels(boardId));
                const actualLabel = updatedCard.labels.slice(-1)[0];

                // Update the state with the actual ID after successful creation
                dispatch(updateLabelOptimistically({ boardId, updatedLabel: { ...newLabel, _id: actualLabel._id } }));
                closeEditModal();
            } catch (error) {
                console.error('Error adding label:', error);
                // Optionally, you could dispatch a rollback action here if necessary
            }
        }
    };

    const updateLabel = async (label, labelId) => {
        const updatedLabel = { ...label, color: currentColor };

        // Optimistically update the state
        dispatch(updateLabelOptimistically({ boardId, updatedLabel }));

        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/boards/${boardId}/labels/${labelId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedLabel),
            });

            if (!response.ok) throw new Error('Failed to update label');
            // Handle success (already updated optimistically, so we may not need to do anything here)
            dispatch(fetchLabels(boardId));

            closeEditModal();
        } catch (error) {
            console.error('Error updating label:', error);
            // Optionally, rollback the optimistic update if the request fails
        }
    };

    const deleteLabel = async (labelId) => {
        // Optimistically remove the label from the state
        dispatch(removeLabelOptimistically({ boardId, labelId }));

        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/boards/${boardId}/labels/${labelId}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (!response.ok) throw new Error('Failed to delete label');
            // Handle success (already updated optimistically, so we may not need to do anything here)
            dispatch(fetchLabels(boardId));

            closeEditModal();
        } catch (error) {
            console.error('Error deleting label:', error);
            // Optionally, you could dispatch a rollback action here if necessary
        }
    };

    const handleCheckboxChange = (label) => {
        const isChecked = cardLabels.some((l) => l._id === label._id);

        // Make the async request to update the backend
        dispatch(updateLabelOnCard({
            cardId,
            labelId: label._id,
            boardId,
            isChecked: !isChecked
        }));
    };

    return (
        <div className="labels-container">
            {isEditModalOpen ? (
                <div className="edit-label-modal">
                    <div className="modal-header">
                        <button className="back-button" onClick={closeEditModal}><span className="material-icons">keyboard_backspace</span></button>
                        <h3>Edit label</h3>
                    </div>
                    <div className="label-preview" style={{ backgroundColor: currentColor }}>
                        {currentLabel}
                    </div>
                    <label>Title</label>
                    <input
                        type="text"
                        value={currentLabel}
                        onChange={e => setCurrentLabel(e.target.value)}
                        className="title-input"
                        ref={titleInputRef}
                    />
                    <label>Select a color</label>
                    <div className="color-picker">
                        {['#61bd4f', '#f2d600', '#ff9f1a', '#eb5a46', '#c377e0', '#0079bf', '#519839', '#d9b51c', '#e79217', '#b04632', '#89609e', '#055a8c', '#6bbd6b', '#efd700', '#f47b13', '#cd5a91', '#6c8ebf', '#5aac44', '#f5dd29', '#ffab4a', '#ef7564', '#cd8de5', '#5ba4cf', '#29cce5', '#6deca9', '#ff8ed4', '#172b4d', '#4d4d4d'].map(color => (
                            <div
                                key={color}
                                className={`color-box ${currentColor === color ? 'selected' : ''}`}
                                style={{ backgroundColor: color }}
                                onClick={() => setCurrentColor(color)}
                            ></div>
                        ))}
                        <div className="color-box">
                            <input
                                type="color"
                                value={currentColor}
                                onChange={(e) => setCurrentColor(e.target.value)}
                                style={{ width: '100%', height: '100%', border: 'none', padding: 0, backgroundColor: currentColor }}
                            />
                        </div>
                    </div>
                    <button className="remove-color-button" onClick={() => setCurrentColor('')}>
                        &times; Remove color
                    </button>
                    <div className="modal-footer">
                        <button className="save-button" onClick={() => createLabel()}>Create</button>
                        {editLabel && (
                            <>
                                <button
                                    className="save-button"
                                    onClick={() => {
                                        updateLabel({ text: currentLabel, color: currentColor }, editLabel._id);
                                    }}>
                                    Save
                                </button>
                                <button
                                    onClick={() => {
                                        deleteLabel(editLabel._id);
                                    }} className="delete-button">
                                    Delete
                                </button>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <div className="labels-header">
                        <h3>Labels</h3>
                    </div>
                    <input type="text" placeholder="Search labels..." className="search-input" />
                    <div className="labels-list">
                        {allLabels.map(label => (
                            <div className="label-item" key={label._id}>
                                <Checkbox
                                    checked={cardLabels.some(l => l._id === label._id)}
                                    onChange={() => handleCheckboxChange(label)}
                                />
                                <div className="label-color" onClick={() => handleCheckboxChange(label)} style={{ backgroundColor: label.color }}>
                                    {label.text}
                                </div>
                                <button className="edit-button" onClick={() => { setEditLabel(label); openEditModal(label.text, label.color); }}>
                                    <span className="material-symbols-outlined">edit</span>
                                </button>
                            </div>
                        ))}
                    </div>
                    <button className="create-label-button" onClick={() => openEditModal()}>Create a new label</button>
                </>
            )}
        </div>
    );
};

export default AddLabels;
