import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Asynchronous thunk to fetch member data
export const fetchMembers = createAsyncThunk(
    'members/fetchMembers',
    async ({ type, id }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/members/${type}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch member data');
            }

            const data = await response.json();
            return { type, members: data }; // Include type to update correct state
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Asynchronous thunk to add a member (with optimistic UI)
export const addMember = createAsyncThunk(
    'members/addMember',
    async ({ cardId, memberId }, { dispatch, rejectWithValue }) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/addMember`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ memberId }),
            });

            if (!response.ok) {
                throw new Error('Failed to add member');
            }

            const data = await response.json();
            return { cardId, member: data };  // Return added member details
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Asynchronous thunk to remove a member (with optimistic UI)
export const removeMember = createAsyncThunk(
    'members/removeMember',
    async ({ cardId, memberId }, { dispatch, rejectWithValue }) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/removeMember`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ memberId }),
            });

            if (!response.ok) {
                throw new Error('Failed to remove member');
            }

            return { cardId, memberId };  // Return the memberId that was removed
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const memberSlice = createSlice({
    name: 'members',
    initialState: {
        member: null,
        boardMembers: [],
        cardMembers: [],
        workspaceMembers: [],
        status: 'idle',
        error: null,
        optimisticUpdates: {}, // Track optimistic updates
    },
    reducers: {
        // Optimistic UI for adding a member
        addMemberOptimistic: (state, action) => {
            const { cardId, member } = action.payload;
            const memberExists = state.cardMembers.some(existingMember => existingMember._id === member._id);
            if (!memberExists) {
                state.cardMembers.push(member);
                state.optimisticUpdates[member._id] = 'ADD'; // Track optimistic update
            }
        },

        // Optimistic UI for removing a member
        removeMemberOptimistic: (state, action) => {
            const { cardId, memberId } = action.payload;
            state.cardMembers = state.cardMembers.filter(member => member._id !== memberId);
            state.optimisticUpdates[memberId] = 'REMOVE'; // Track optimistic update
        },

        // Rollback function in case optimistic update fails
        rollbackOptimisticUpdate: (state, action) => {
            const { cardId, member, type } = action.payload;
            if (type === 'ADD') {
                state.cardMembers = state.cardMembers.filter(m => m._id !== member._id); // Revert member addition
            } else if (type === 'REMOVE') {
                state.cardMembers.push(member); // Re-add the removed member
            }
            delete state.optimisticUpdates[member._id]; // Remove optimistic tracking
        },
        memberInfo: (state, action) => {
            state.member = action.payload.member;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch members lifecycle
            .addCase(fetchMembers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMembers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { type, members } = action.payload;
                if (type === 'board') {
                    state.boardMembers = members;
                } else if (type === 'card') {
                    state.cardMembers = members;  // Update with the fresh list from the server
                } else if (type === 'workspace') {
                    state.workspaceMembers = members;
                }
            })
            .addCase(fetchMembers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            // Add member lifecycle
            .addCase(addMember.fulfilled, (state, action) => {
                const { cardId, member } = action.payload;
                state.cardMembers = state.cardMembers.map(m => m._id === member._id ? member : m);  // Ensure correct state
                delete state.optimisticUpdates[member._id];  // Remove from optimistic tracking
            })
            .addCase(addMember.rejected, (state, action) => {
                const { cardId, memberId } = action.meta.arg;
                const member = state.cardMembers.find(m => m._id === memberId);
                if (member) {
                    state.cardMembers = state.cardMembers.filter(m => m._id !== member._id);  // Remove member
                    delete state.optimisticUpdates[memberId];  // Clear optimistic tracking
                }
            })

            // Remove member lifecycle
            .addCase(removeMember.fulfilled, (state, action) => {
                const { memberId } = action.payload;
                state.cardMembers = state.cardMembers.filter(m => m._id !== memberId);  // Remove member from card
                delete state.optimisticUpdates[memberId];  // Clear optimistic tracking
            })
            .addCase(removeMember.rejected, (state, action) => {
                const { cardId, memberId } = action.meta.arg;
                const member = state.boardMembers.find(m => m._id === memberId);
                if (member) {
                    state.cardMembers.push(member);  // Re-add member if removal failed
                    delete state.optimisticUpdates[memberId];  // Clear optimistic tracking
                }
            });
    }
});

// Exporting actions and reducer
export const { memberInfo, addMemberOptimistic, removeMemberOptimistic, rollbackOptimisticUpdate } = memberSlice.actions;
export default memberSlice.reducer;
