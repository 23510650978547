import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const boardIdSlice = createSlice({
  name: 'boardId',
  initialState,
  reducers: {
    setBoardId(state, action) {
      return action.payload;
    },
    clearBoardId(state) {
      return null;
    },
  },
});

export const { setBoardId, clearBoardId } = boardIdSlice.actions;

export const selectBoardId = (state) => state.boardId;

export default boardIdSlice.reducer;
