import { useEffect, useState } from 'react';

function useIsScrolling(ref) {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolling(true);
        };

        const handleMouseMove = () => {
            setIsScrolling(false); // Remove the 'scrolling' class when the mouse moves
        };

        const element = ref.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            document.addEventListener('mousemove', handleMouseMove);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [ref]);

    return isScrolling;
}

export default useIsScrolling;
