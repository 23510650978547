import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "./scss/ExportImport.scss";
import ZoobbeSelect from '../Global/ZoobbeSelect';
import { config } from '../../config';
import Checkbox from '../Global/Checkbox';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';

const ExportImport = () => {
    const [importFile, setImportFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();

    const { workspaces } = useSelector(state => state.workspaces);

    const workspaceOptions = workspaces?.map(workspace => ({
        value: workspace._id.toString(),
        label: workspace.name,
    }));

    const visibilityOptions = [
        { value: 'workspace', label: 'Workspace' },
        { value: 'public', label: 'Public' },
        { value: 'private', label: 'Private' },
    ];
    const importFromOptions = [
        { value: 'trello', label: 'Trello' },
        { value: 'fluent-boards', label: 'Fluent Boards' },
    ];

    const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceOptions[0]);
    const [selectedVisibility, setSelectedVisibility] = useState(visibilityOptions[0]);
    const [importFromSelect, setImportFromSelect] = useState(visibilityOptions[0]);

    const handleWorkspaceSelect = (workspace) => {
        setSelectedWorkspace(workspace);
        console.log('Selected Workspace:', workspace);
    };

    const handleVisibilitySelect = (visibility) => {
        setSelectedVisibility(visibility);
        console.log('Selected visibility:', visibility);
    };

    const handleImportFromSelect = (importFrom) => {
        setImportFromSelect(importFrom);
        console.log('Selected visibility:', importFrom);
    };

    const handleExport = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('accessToken');

        try {
            const response = await fetch(config.API_URI + `/api/export`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exported_data.json');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error exporting data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleImport = (e) => {
        setImportFile(e.target.files[0]);
    };

    const uploadImportFile = async () => {
        if (!importFile) return;
        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', importFile);
        formData.append('workspaceId', selectedWorkspace.value);
        formData.append('isImportMembers', isChecked);

        const token = localStorage.getItem('accessToken');

        try {
            console.log(`${config.API_URI}/api/${selectedWorkspace.value}/import`);

            const response = await fetch(`${config.API_URI}/api/${selectedWorkspace.value}/import`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to import board');
            }

            alert('Import successful!');
            dispatch(fetchWorkspaces());
        } catch (error) {
            console.error('Error importing data:', error);
            alert(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        dispatch(fetchWorkspaces());
    }, []);

    return (
        <div className="export-import-container">
            <div className="select-workspace-to-import">
                <label htmlFor="workspace-type">Workspace</label>
                <ZoobbeSelect
                    options={workspaceOptions}
                    defaultSelectedOption={0}
                    onSelect={handleWorkspaceSelect}
                />

                <label htmlFor="workspace-type">Visibility</label>
                <ZoobbeSelect
                    options={visibilityOptions}
                    defaultSelectedOption={0}
                    onSelect={handleVisibilitySelect}
                />

                <label htmlFor="workspace-type">Import From</label>

                <ZoobbeSelect
                    options={importFromOptions}
                    defaultSelectedOption={0}
                    onSelect={handleImportFromSelect}
                />

                <div className='create-user-checkbox'>
                    <label>
                        <Checkbox
                            checked={isChecked}
                            onChange={(checked) => setIsChecked(checked)}
                        />
                        <span>Import/Create Board Members</span>
                    </label>
                </div>

            </div>

            {importFile && (
                <div className="uploaded-file-name">
                    Uploaded file: {importFile.name}
                </div>
            )}

            <div className="buttons">
                <button className="export-btn" onClick={handleExport} disabled={isLoading}>
                    <span className="material-symbols-outlined">
                        download
                    </span>

                    {isLoading ? 'Exporting...' : 'Export'}
                </button>
                <label htmlFor="import-file" className="import-label">
                    <span className="material-symbols-outlined">
                        upload
                    </span>
                    Upload Import File
                    <input
                        id="import-file"
                        type="file"
                        accept=".json"
                        onChange={handleImport}
                        className="import-input"
                    />
                </label>
                <button
                    className="import-btn"
                    onClick={uploadImportFile}
                    disabled={!importFile || isLoading || !selectedWorkspace?.value}
                >
                    {isLoading ? 'Importing...' : 'Import'}
                </button>
            </div>
        </div>
    );
};

export default ExportImport;
