import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMemberOptimistic, fetchMembers, removeMemberOptimistic, addMember, removeMember } from '../../redux/Slices/memberSlice';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import { uploadAttachment } from '../../redux/Slices/attachmentSlice';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import { config } from '../../config';
import { useNavigate } from 'react-router-dom';

const CardDetailsSidebar = ({ cardId, boardId, isArchived, boardLink }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { boardMembers, cardMembers } = useSelector(state => state.member);

    const [error, setError] = useState(null);
    const [archived, setArchived] = useState(isArchived);
    const [isDeleted, setIsDeleted] = useState(false);
    const fileInputRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (boardId && !boardMembers) dispatch(fetchMembers({ type: 'board', id: boardId }));
        if (cardId && !cardMembers) dispatch(fetchMembers({ type: 'card', id: cardId }));
    }, [boardId, cardId, dispatch, boardMembers, cardMembers]);

    let joinQueue = Promise.resolve(); // Start with a resolved promise

    const handleJoinToCard = (e, member) => {
        console.log(member);
        // Add the request to the queue
        joinQueue = joinQueue.then(async () => {
            // Optimistic update
            dispatch(addMemberOptimistic({ cardId, member: member.user }));

            try {
                await dispatch(addMember({ cardId, memberId: member.user._id })).unwrap();

                dispatch(fetchActivities({cardId}));

                console.log('Joined to card');
            } catch (error) {
                dispatch(removeMemberOptimistic({ cardId, memberId: member.user._id }));
                setError('Failed to add member.');
            }
        });
    };

    const handleLeaveToCard = (e, member) => {

        // Add the request to the queue
        joinQueue = joinQueue.then(async () => {
            // Optimistic update
            dispatch(removeMemberOptimistic({ cardId, member: member.user }));

            try {
                await dispatch(removeMember({ cardId, memberId: member.user._id })).unwrap();

                dispatch(fetchActivities({cardId}));

            } catch (error) {
                dispatch(addMemberOptimistic({ cardId, memberId: member.user._id }));
                setError('Failed to add member.');
            }
        });
    };


    const handleArchiveCard = async (archivedStatus) => {
        setArchived(archivedStatus); // Update local state to reflect change instantly

        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/archive/${cardId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ archived: archivedStatus }),
            });

            if (!response.ok) {
                throw new Error('Failed to archive the card');
            }

            const data = await response.json();
            console.log('Card archive status updated:', data);
        } catch (error) {
            // Optionally revert the UI if the API request fails
            setArchived(!archivedStatus);
            console.error('Error archiving the card:', error);
        }
    };

    const handleDeleteCard = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/delete/${cardId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the card');
            }

            const data = await response.json();
            setIsDeleted(true); // Update local state to reflect card deletion instantly
            navigate(boardLink);
            console.log('Card deleted successfully:', data);
        } catch (error) {
            console.error('Error deleting the card:', error);
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            dispatch(uploadAttachment({ file, cardId }));
        }
    };

    const { handlePopoverClick } = useHandlePopoverClick();

    const memberIds = cardMembers?.map(member => member?._id);
    const isUserMember = user && user.user ? memberIds?.includes(user.user._id) : false;

    if (isDeleted) return null; // Don't render the sidebar if the card is deleted

    const actionButtons = [
        !isUserMember && {
            title: 'Join',
            icon: 'person_add',
            text: 'Join',
            onClick: (e) => handleJoinToCard(e, user),
            id: 'card-back-join-button'
        },
        isUserMember && {
            title: 'Leave',
            icon: 'person_remove',
            text: 'Leave',
            onClick: (e) => handleLeaveToCard(e, user),
            id: 'card-back-leave-button'
        },
        {
            title: 'Members',
            icon: 'people_outline',
            text: 'Members',
            onClick: (e) => handlePopoverClick(e, 'cardMemberesPopover'),
            id: 'card-back-members-button'
        },
        {
            title: 'Labels',
            icon: 'label_outline',
            text: 'Labels',
            onClick: (e) => handlePopoverClick(e, 'addLabels'),
            id: 'card-back-labels-button'
        },
        {
            title: 'Checklist',
            icon: 'checklist',
            text: 'Checklist',
            onClick: (e) => handlePopoverClick(e, 'addChecklist'),
            id: 'card-back-checklist-button'
        },
        {
            title: 'Dates',
            icon: 'hourglass_top',
            text: 'Dates',
            onClick: (e) => handlePopoverClick(e, 'addDueDate'),
            id: 'card-back-dates-button'
        },
        {
            title: 'Attachment',
            icon: 'attach_file',
            text: 'Attachment',
            // onClick: () => fileInputRef.current.click(),
            onClick: (e) => handlePopoverClick(e, 'attachment'),
            id: 'card-back-attachment-button'
        },
        {
            title: 'Cover',
            icon: 'video_label',
            text: 'Cover',
            onClick: () => console.log('Cover button clicked'),
            id: 'card-back-cover-button'
        },
        {
            title: 'Move',
            icon: 'arrow_right_alt',
            text: 'Move',
            onClick: (e) => handlePopoverClick(e, 'moveCard'),
            id: 'card-back-move-card-button'
        },
        {
            title: 'Copy',
            icon: 'copy_all',
            text: 'Copy',
            onClick: (e) => handlePopoverClick(e, 'copyCard'),
            id: 'card-back-copy-card-button'
        },
        {
            title: 'Make template',
            icon: 'web_asset',
            text: 'Make template',
            onClick: () => console.log('Make template button clicked'),
            id: 'card-back-make-template-button'
        },
        !archived && {
            title: 'Archive',
            icon: 'archive',
            text: 'Archive',
            onClick: () => handleArchiveCard(true),
            id: 'card-back-archive-button'
        },
        archived && {
            title: 'Send to board',
            icon: 'refresh',
            text: 'Send to board',
            onClick: () => handleArchiveCard(false),
            id: 'card-back-send-to-board-button'
        },
        archived && {
            title: 'Delete',
            icon: 'delete_outline',
            text: 'Delete',
            // onClick: () => handleDeleteCard(),
            onClick: (e) => handlePopoverClick(e, 'deleteCard'),

            id: 'card-back-delete-card-button'
        },
        {
            title: 'Share',
            icon: 'share',
            text: 'Share',
            onClick: (e) => handlePopoverClick(e, 'shareCard'),

            id: 'card-back-share-button'
        }
    ].filter(Boolean);

    return (
        <div className="window-sidebar">
            <div className="window-module u-clearfix">
                <h3 className="mod-no-top-margin js-sidebar-add-heading">Add to card</h3>
                <ul className="action-buttons-list">
                    {actionButtons.map((button, index) => (
                        <li key={index} className="action-button-item">
                            <button
                                className="button-with-icon"
                                type="button"
                                onClick={button.onClick}
                                title={button.title}
                                id={button.id}
                                data-testid={button.id}
                            >
                                <span className="material-symbols-outlined">{button.icon}</span>
                                {button.text}
                            </button>

                            {button.text === 'Attachment' && (
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CardDetailsSidebar;
