import { useDispatch, useSelector } from 'react-redux';
import { togglePopover } from '../redux/Slices/popoverSlice';
import { useRef } from 'react';

const useHandlePopoverClick = () => {
    const dispatch = useDispatch();
    const popoverState = useSelector((state) => state.popover);

    const handlePopoverClick = (e, contentId, props = {}) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };
        dispatch(togglePopover({ contentId, position, target, props }));
    };

    return { popoverState, handlePopoverClick };
};



export default useHandlePopoverClick;
