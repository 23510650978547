import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client';

import { useDispatch, useSelector } from 'react-redux';

import { toSlug } from '../../utils/helpers';

import { openModal } from '../../redux/Slices/modalSlice';

import CreateWorkspace from './CreateWorkspace';

import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { config } from '../../config';


import './scss/workspace.scss';

import WorkspaceSidebar from './WorkspaceSidebar';
import BoardSection from './BoardSection';
import { fetchUser } from '../../redux/Slices/thunks';

import { ActionTypes } from '../../sockets/ActionTypes';
import { showToast } from '../../redux/Slices/toastSlice';
import RecentView from './RecentView';

const WorkSpace = () => {

    const [boards, setBoards] = useState([]);
    const [showFormForWorkspace, setShowFormForWorkspace] = useState(null);
    const [boardTitle, setBoardTitle] = useState('');
    const { workspaces, status, error } = useSelector(state => state.workspaces);

    const [workspaceId, setWorkspaceId] = useState();


    const workspaceSlug = useParams('workspaceSlug');
    const { user } = useSelector((state) => state.user);



    const dispatch = useDispatch();

    useEffect(() => {
        if (workspaces.length === 0) {
            dispatch(fetchWorkspaces()).unwrap().then((fetchedWorkspaces) => {
                if (!fetchedWorkspaces || fetchedWorkspaces.length === 0) {
                    console.log('Failed to load workspaces or user has no workspaces');
                }
            }).catch((error) => {
                console.error('Error fetching workspaces:', error);
            });
        }
    }, [dispatch, workspaces.length]);

    useEffect(() => {
        dispatch(fetchWorkspaces());
    }, [dispatch, workspaceSlug]);



    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
    };

    const handleCreateBoard = (defaultSelectedOption) => {
        dispatch(openModal({ modalType: 'CREATE_BOARD', modalData: { defaultSelectedOption } }));
    };

    const [openWorkspaces, setOpenWorkspaces] = useState({});

    useEffect(() => {
        const initialOpenWorkspaces = {};
        workspaces.slice(0, 2).forEach(workspace => {
            initialOpenWorkspaces[workspace.name] = true;
        });
        setOpenWorkspaces(initialOpenWorkspaces);
    }, [workspaces]);

    const toggleOptions = (workspaceName) => {
        setOpenWorkspaces(prevState => ({
            ...prevState,
            [workspaceName]: !prevState[workspaceName]
        }));
    };

    useEffect(() => {
        const fetchActionLists = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI + `/api/boards`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    // setIsValid(false);
                    throw new Error('Failed to fetch boards');
                }

                const data = await response.json();
                setBoards(data);
            } catch (error) {
                console.error('Error fetching boards:', error);
            }
        };

        fetchActionLists();
    }, []);

    const handleAddButton = (workspaceId) => {
        setShowFormForWorkspace(prevWorkspaceId => prevWorkspaceId === workspaceId ? null : workspaceId);
    };

    const handleHideForm = () => {
        setShowFormForWorkspace(null);
    };

    useEffect(() => {
        if (!user) {
            dispatch(fetchUser());
            return;
        }

        console.log("this is curreent use", user.user._id);

        const userId = user.user._id
        const username = user.user.username
        const socket = socketIOClient(config.API_URI);

        // Join user-specific WebSocket room
        socket.emit('joinUser', userId, username);

        // Centralized event listener
        socket.on('user-action', (data) => {

            console.log({ data });
            switch (data.action) {
                case ActionTypes.MEMBER_ADDED:
                    dispatch(fetchWorkspaces());
                    break;
                case ActionTypes.MEMBER_REMOVED:
                    dispatch(fetchWorkspaces());
                    break;
                case ActionTypes.ROLE_CHANGED:
                    dispatch(fetchWorkspaces());
                    break;
                case ActionTypes.BOARD_CREATED:
                    dispatch(fetchWorkspaces());
                    break;
                case ActionTypes.BOARD_UPDATED:
                    dispatch(fetchWorkspaces());
                    break;
                default:
                    console.log('Unhandled user action:', data.action);

            }
        });

        // Cleanup on component unmount
        return () => {
            socket.disconnect();
        };
    }, [dispatch, user]);




    return (
        <div className="zppbbe-workspace-wrapper">
            <div className="zoobbe-workspace-container">
                <aside className="zoobbe-sidebar">
                    <WorkspaceSidebar
                        openWorkspaces={openWorkspaces}
                        toggleOptions={toggleOptions}
                        handleCreateWorkspace={handleCreateWorkspace}
                    />
                </aside>

                <main className="zoobbe-main-content">
                    <div className="zoobbe-recently-viewed">
                        {
                            workspaces.length > 0 && (
                                <section className="zoobbe-your-workspaces">
                                    <h2>Recently viewed</h2>

                                    <div className="zoobbe-workspace-section">
                                        <RecentView />
                                    </div>
                                </section>
                            )
                        }

                    </div>
                    {
                        workspaces.length > 0 && (
                            <section className="zoobbe-your-workspaces">
                                <h2>Your Workspaces</h2>
                                {workspaces.map((workspace, defaultSelectedOption) => {

                                    return (
                                        <div className="zoobbe-workspace-section" key={workspace._id}>
                                            <div className='boards-page-board-section-header'>
                                                <div className="zoobbe-workspace-card">
                                                    <span className="zoobbe-workspace-icon">{workspace.name ? workspace.name.charAt(0) : ''}</span>
                                                    <span className="zoobbe-workspace-title">{workspace.name}</span>
                                                </div>
                                                <div className="zoobbe-workspace-actions">
                                                    <Link to={`/w/${workspace.shortId}/boards`} >Boards</Link>
                                                    {/* <Link to={`/w/${workspace.shortName}/Views`} >Views</Link> */}
                                                    <Link to={`/w/${workspace.shortId}/members`} >Members ({workspace.members.length})</Link>
                                                    <Link to={`/w/${workspace.shortId}/settings`} >Settings</Link>
                                                    <Link to={`/w/${workspace.shortId}/upgrade`} >Upgrade</Link>
                                                </div>
                                            </div>

                                            <BoardSection workspace={workspace} defaultSelectedOption={defaultSelectedOption} />

                                        </div>
                                    )
                                })}
                            </section>
                        )
                    }

                    <section className="zoobbe-guest-workspaces">
                        <h2>Guest Workspaces</h2>
                        <div className="zoobbe-workspace-card">Akash</div>


                    </section>
                </main>
            </div>
        </div>
    );
};

export default WorkSpace;
