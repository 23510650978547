import React from 'react';

const Spinner = ({ size = 50, color = '#3498db', speed = 1 }) => {
  const spinnerStyle = {
    width: `${size}px`,
    height: `${size}px`,
    border: `${size / 8}px solid`,
    borderTop: `${size / 8}px solid ${color}`,
    borderRadius: '50%',
    borderColor: 'rgb(52, 152, 219) rgb(68 84 111) rgb(52, 152, 219)',
    animation: `spin ${speed}s linear infinite`,
  };

  return (
    <div style={spinnerStyle}></div>
  );
};

export default Spinner;

// In your CSS file or <style> tag (you can also inline it, but this makes it reusable)
const styles = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
