import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// External Libraries
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-indicator/box";

import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

// Local Utilities and Components
import {
  find,
  getChecklistStatus,
  relative,
  toSlug,
} from '../../utils/helpers';
import { fetchCardMembers } from '../../redux/Slices/cardMemberSlice';
import { fetchLabels } from '../../redux/Slices/labelSlice';
import useOutsideClick from '../../hooks/useOutsideClick';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import MemberImage from '../Global/MemberImage';
// import CardPreview from './CardPreview';

const Card = ({ card, moveCard, moveActionList }) => {
  // Destructure Card Properties
  const {
    _id,
    title,
    permalink,
    users,
    labels,
    watchers,
    checklists,
    description,
    comments,
    attachments,
    actionList,
  } = card || {};

  const members = users;

  // State Hooks
  const [isEditCard, setIsEditCard] = useState(false);
  const [cardTitle, setCardTitle] = useState(title || '');
  const [isDragging, setDragging] = useState(false);
  const [cardHighlight, setCardHighlight] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [closestEdge, setClosestEdge] = useState(null);
  const [isHoverEnabled, setIsHoverEnabled] = useState(true);
  const [preview, setPreview] = useState(<div>Hellow world</div>);

  const isDraggingRef = useRef(false);


  console.log({ card });

  // Refs
  const cardTitleRef = useRef(null);
  const cardRef = useRef(null);

  // Redux and Router
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  // Intersection Observer to toggle hover
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHoverEnabled(entry.isIntersecting);
      },
      { threshold: 1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  // Drag and Drop Setup
  useEffect(() => {
    const element = cardRef.current;
    if (!element) return;

    // Setup Drag and Drop
    const combined = combine(
      // Draggable configuration
      draggable({
        element,
        dragHandle: element,
        getInitialData: () => ({ ...card, type: 'CARD' }),
        onDragStart() {
          isDraggingRef.current = true;
          setDragging(true);
        },
        onDrag() {
          console.log('dragging');
        },
        onDragEnd() {
          console.log('drag end');
          isDraggingRef.current = false;
          setDragging(false);
        },
        onDrop() {
          setDragging(false);
        },
      }),

      // Drop target configuration
      dropTargetForElements({
        element,
        // canDrop: (args) => args.source.data.type === "CARD",
        getIsSticky: () => true,
        getData: ({ input, element }) => {
          const data = { ...card, type: 'CARD' };
          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDragEnter: (args) => {
          const { source, self } = args;
          setClosestEdge(extractClosestEdge(self.data));
          setDragging(true);

          console.log(source.data.type)

          // if (!self || self.data._id === source.data._id) return;
          const target = self;
          const targetListId = self.data.actionList?._id;
          const targetPosition = self.data.order;
          const cardId = source.data._id;

          if (source.data.type === 'ACTIONLIST') {
            setDragging(false);
            console.log('Calling from cardId: ');
            moveActionList(
              source.data._id,
              target.data.actionList._id,
            )

          }

          if (source.data.type === 'CARD') {
            moveCard(cardId, targetListId, targetPosition);
          }
        },
        onDrag() {
          // Handle dragging here (if needed)
        },
        onDragLeave: () => {
          setClosestEdge(null);
          setDragging(false);
          console.log('Drage Leave');

        },
        onDrop: () => {
          setClosestEdge(null);
          setDragging(false);
        },
      })
    );

    // Cleanup on component unmount
    return combined;
  }, [card, cardRef.current, moveCard]);


  // Style Definitions
  const draggingStyle = {
    opacity: '0.3',
  };

  const style = {
    // ...(cardHighlight ? { border: '2px solid red' } : {}),
    ...(isDragging ? draggingStyle : {}),
    transition: 'all 0.3s ease',
  };


  // Adjust Textarea Height and Focus
  useEffect(() => {
    if (isEditCard && cardTitleRef.current) {
      adjustTextareaHeight(cardTitleRef.current, cardTitle, 16);
      cardTitleRef.current.focus();
    }
  }, [isEditCard, cardTitle]);

  // Handle Click Outside to Update and Hide Form
  useOutsideClick(cardTitleRef, () => {
    handleUpdateCard();
    handleHideForm();
  });

  // Early Return if Card is Null or Undefined
  if (!card) return null;

  // Derived Variables
  const cardSlug = toSlug(title || '');
  const checklistsStatus = getChecklistStatus(checklists);

  // Handlers
  const handleUpdateCard = () => {
    // Dispatch action to update the card title (implement this action in your Redux slice)
    // dispatch(updateCardTitle({ id: _id, title: cardTitle }));
    setIsEditCard(false);
  };

  const handleHideForm = () => {
    setIsEditCard(false);
  };

  const adjustTextareaHeight = (textarea, content, minus) => {
    textarea.style.height = '24px';
    const scrollHeight = textarea.scrollHeight - minus;
    textarea.style.height = `${scrollHeight}px`;
  };

  const handleOpenCard = (e, cardId) => {
    navigate(relative(permalink));
  };

  // Determine if Footer Should Be Rendered
  const shouldRenderFooter =
    (watchers?.length > 0 && watchers.some(watcher => watcher._id === user?.user?._id)) ||
    !!description ||
    (comments?.length > 0) ||
    (checklistsStatus[1] > 0) ||
    (attachments?.length > 0) ||
    (members.length > 0);


  return (
    <div className='card-wrapper'>
      <div
        className={`card-pemalink-wrapper${isEditCard ? ' edit-mode' : ''}`}
        ref={cardRef}
        style={style}
      >
        {isEditCard ? (
          <form onSubmit={handleUpdateCard}>
            <textarea
              ref={cardTitleRef}
              name="update-card"
              placeholder="Update card title"
              rows="1"
              value={cardTitle}
              onChange={(e) => setCardTitle(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleUpdateCard();
                }
              }}
              onFocus={(e) => e.target.select()}
              required
            />
          </form>
        ) : (
          <>
            <div className="zoobbe-card">
              <div className="card-header">
                {labels && (
                  <div className="card-labels">
                    {labels.map(label => (
                      <span
                        key={label._id}
                        style={{ backgroundColor: label.color }}
                        className={`label ${toSlug(label.text)}-label`}
                        onClick={() => console.log(label.text)}
                      >
                        {label.text}
                      </span>
                    ))}
                  </div>
                )}
              </div>

              <p onClick={() => setIsEditCard(true)}>{cardTitle}</p>

              {shouldRenderFooter && (
                <div className="zoobbe-card-footer">
                  <div className="zoobbe-card-front-badges">
                    {watchers?.length > 0 && watchers.some(watcher => watcher._id === user?.user?._id) && (
                      <span className="badge-card-subscribe badge-icon">
                        <span className="material-icons-outlined">visibility</span>
                      </span>
                    )}
                    {description && (
                      <span className="badge-card-description badge-icon">
                        <span className="material-icons-outlined">subject</span>
                      </span>
                    )}
                    {comments?.length > 0 && (
                      <span className="badge-card-comments badge-icon">
                        <span className="material-icons-outlined">mode_comment</span>
                        <span className="comments-count count-number">{comments.length}</span>
                      </span>
                    )}
                    {checklistsStatus[1] > 0 && (
                      <span className="badge-card-checklists badge-icon">
                        <span className="material-symbols-outlined">task_alt</span>
                        <span className="checklists-count count-number">{checklistsStatus[0]}/{checklistsStatus[1]}</span>
                      </span>
                    )}
                    {attachments?.length > 0 && (
                      <span className="badge-card-attachments badge-icon">
                        <span className="material-symbols-outlined">attach_file</span>
                        <span className="attachment-count count-number">{attachments.length}</span>
                      </span>
                    )}
                  </div>
                  {members.length > 0 && (
                    <div className="avatars">
                      <MemberImage cardId={_id} members={members} size={28} />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              title={permalink}
              onClick={(e) => handleOpenCard(e, _id)}
              className="card-pemalink"
            ></div>
          </>
        )}
        {!isEditCard && (
          <div className="edit-card" onClick={() => setIsEditCard(true)}>
            <span className="material-symbols-outlined">edit</span>
          </div>
        )}
      </div>
    </div>
  );


};

export default Card;
