import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async action to fetch activities
export const fetchActivities = createAsyncThunk(
    'activities/fetchActivities',
    async ({ cardId, isActivityDetails }, { rejectWithValue }) => {
        const token = localStorage.getItem('accessToken');

        console.log(isActivityDetails)
        let activityQuery = '';
        if (isActivityDetails !== undefined) {
            activityQuery = `?isActivityDetails=${isActivityDetails}`;
        }
        try {
            const response = await fetch(
                `${config.API_URI}/api/cards/${cardId}/activities${activityQuery}`, // Send as query parameter
                {
                    method: 'GET', // GET request doesn't use body
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json', // Optional but good to have
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch activities');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


// Activities slice
const activitiesSlice = createSlice({
    name: 'activities',
    initialState: {
        activities: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchActivities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                state.loading = false;
                state.activities = action.payload;
            })
            .addCase(fetchActivities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default activitiesSlice.reducer;
