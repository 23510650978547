import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async thunk to fetch the board by ID
export const fetchBoardById = createAsyncThunk(
    'board/fetchBoardById',
    async (boardId, { getState, rejectWithValue }) => {
        const state = getState();
        const _boardId = state.board.boardId || null;
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${config.API_URI}/api/boards/${boardId || _boardId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch the board');
            }

            const fetchedBoard = await response.json();
            return fetchedBoard || { actionLists: [] };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const boardSlice = createSlice({
    name: 'board',
    initialState: {
        boardId: null,
        board: null,
        isLoading: false,
        error: null,
    },
    reducers: {
        setBoardId: (state, action) => {
            state.boardId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBoardById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchBoardById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.board = action.payload;
            })
            .addCase(fetchBoardById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { setBoardId } = boardSlice.actions;
export default boardSlice.reducer;
