import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ZoobbeSelect from '../Global/ZoobbeSelect';

import './scss/AddChecklist.scss';
import { fetchChecklists, localAddChecklist, revertChecklistAddition } from '../../redux/Slices/checklistSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { config } from '../../config';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import SearchSelect from '../Global/SearchSelect';

const AddChecklist = () => {
    const [title, setTitle] = useState('Checklist');
    const [selectedChecklist, setSelectedChecklist] = useState(null);

    const inputRef = useRef(null);
    const { card } = useSelector((state) => state.card);
    const cardId = card.shortId;


    const dispatch = useDispatch();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, []);

    const checklistOptions = [
        { value: 'none', label: '(none)' },
    ];

    const handleChecklistSelect = (checklist) => {
        setSelectedChecklist(checklist);
        console.log('Selected Checklist:', checklist);
    };

    const handleAddChecklist = async () => {
        if (!title) {
            alert("Please enter a title for the checklist");
            return;
        }

        // Generate a temporary ID for the optimistic update
        const tempId = `temp-${Date.now()}`;
        const optimisticChecklist = { _id: tempId, title, items: [] };

        // Dispatch the optimistic update
        dispatch(localAddChecklist(optimisticChecklist));
        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));

        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title })
            });

            if (response.ok) {
                const updatedCard = await response.json();
                // Fetch the updated checklists from the server
                dispatch(fetchChecklists(cardId));

                // Optionally close the popover and reset the title
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
                setTitle('Checklist');

                // Fetch the updated activities
                dispatch(fetchActivities({cardId}));
            } else {
                console.error('Failed to add checklist');
                // Revert the optimistic update if the request fails
                dispatch(revertChecklistAddition({ tempId }));
            }
        } catch (error) {
            console.error('Error:', error);
            // Revert the optimistic update in case of an error
            dispatch(revertChecklistAddition({ tempId }));
        }
    };


    return (
        <div className="add-checklist">
            <h2>Add checklist</h2>

            <div className="group">
                <h3>Title</h3>
                <input
                    type="text"
                    placeholder="Checklist"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    ref={inputRef}
                />
            </div>
            <div className="group">
                <h3>Copy items from…</h3>
                <SearchSelect
                    options={checklistOptions}
                    onSelect={handleChecklistSelect}
                    placeholder="Select an option"
                    isGrouped={false}
                    defaultValue={'none'}
                />
            </div>
            <button onClick={handleAddChecklist}>Add</button>
        </div>
    );
};

export default AddChecklist;
