import React from 'react';
import { useLocation } from 'react-router-dom';
import Board from './Board';
import CardDetails from './CardDetails';

import './scss/index.scss';



function BoardWrapper() {
    const location = useLocation();
    const isCardPath = location.pathname.startsWith('/c/');

    return (
        <div id='zoobbe-board'>
            <Board />
            {isCardPath && <CardDetails />}
        </div>
    );
}

export default BoardWrapper;
