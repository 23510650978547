import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isOpen: false,
    modalType: null,
    modalData: null, // Add a new state property to hold additional data
  },
  reducers: {
    openModal: (state, action) => {
      const { modalType, modalData } = action.payload;
      state.isOpen = true;
      state.modalType = modalType;
      state.modalData = modalData; // Store the additional data
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.modalType = null;
      state.modalData = null; // Clear the additional data
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
