import React, { useState } from 'react';
import './scss/CreateWorkspace.scss';

import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import { Navigate } from 'react-router-dom';
import ZoobbeSelect from '../Global/ZoobbeSelect';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { closeModal } from '../../redux/Slices/modalSlice';
import { config } from '../../config';

const CreateWorkspace = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceType, setWorkspaceType] = useState('engineering-it');
  const [workspaceDescription, setWorkspaceDescription] = useState('');

  const dispatch = useDispatch();

  const handleCreateWorkspace = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('accessToken');
    if (!token) {
      return false;
    }

    const workspaceData = {
      name: workspaceName,
      type: workspaceType,
      description: workspaceDescription
    };

    try {
      const response = await fetch(config.API_URI + '/api/workspaces/me', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(workspaceData)
      });

      if (!response.ok) {
        dispatch(showToast({ message: 'Failed to create workspace', type: 'error' }));
        throw new Error('Failed to create workspace');

      }

      const data = await response.json();
      if (data) {
        dispatch(showToast({ message: data.message, type: 'success' }));
        dispatch(closeModal());
        dispatch(fetchWorkspaces());
        <navigate to={`/w/${data.workspace.shortname}`} />
      }


    } catch (error) {
      dispatch(showToast({ message: 'Error creating workspace:' + error, type: 'error' }));

    }
  };

  const workpaceTypes = [
    { value: 'engineering-it', label: 'Engineering-IT' },
    { value: 'education', label: 'Education' },
    { value: 'marketing', label: 'Marketing' },
  ];

  const handleWorkspaceTypeSelect = (type) => {
    setWorkspaceType(type.value);
    console.log('Selected Workspace:', type);
  };



  return (
    <div className="workspace-form">
      <h1>Let's build a Workspace</h1>
      <p>Boost your productivity by making it easier for everyone to access boards in one location.</p>
      <form onSubmit={handleCreateWorkspace}>
        <label htmlFor="workspace-name">Workspace name</label>
        <input
          type="text"
          id="workspace-name"
          value={workspaceName}
          onChange={(e) => setWorkspaceName(e.target.value)}
          placeholder="Zoobbe"
        />

        <label htmlFor="workspace-type">Workspace type</label>
        <ZoobbeSelect
          options={workpaceTypes}
          defaultSelectedOption={0}
          onSelect={handleWorkspaceTypeSelect}
        />


        <label htmlFor="workspace-description">Workspace description <span>(Optional)</span></label>
        <textarea
          id="workspace-description"
          value={workspaceDescription}
          onChange={(e) => setWorkspaceDescription(e.target.value)}
          placeholder="Our team organizes everything here."
        />

        <button type="submit">Continue</button>
      </form>
    </div>
  );
};

export default CreateWorkspace;
