import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useVirtualizer } from '@tanstack/react-virtual';
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { showToast } from '../../redux/Slices/toastSlice';
import { fetchUsers } from '../../redux/Slices/thunks';
import { config } from '../../config';
import { useBoard } from '../../context/BoardContext';
import { find, getFilters } from '../../utils/helpers';
import { selectBoardId, setBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { setFilterQuery } from '../../redux/Slices/filterQuerySlice';
import ActionList from './ActionList';
import ActionListVirtualized from './ActionListVirtualized';
import ActionListPND from './ActionListPND';
import AddActionListButton from './AddActionListButton';
import Navbar from './Navbar';
import NotFound from '../400';
import useIsScrolling from '../../hooks/useIsScrolling';
import SkeletonBoard from '../Skeletons/SkeletonBoard';
import { fetchBoardById } from '../../redux/Slices/boardSlice';

const Board = () => {
  const { boardId, boardSlug, cardId } = useParams();
  const { setBoardPermalink } = useBoard();
  const _boardId = useSelector(selectBoardId);
  const getBoard = useSelector((state) => state.board.board);

  const dispatch = useDispatch();
  const location = useLocation();


  const [showForm, setShowForm] = useState(false);
  const [listTitle, setActionListTitle] = useState('');
  const [board, setBoard] = useState({ actionLists: [] });
  const [isValid, setIsValid] = useState(true);
  const [openListId, setOpenListId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [highlight, setHighlight] = useState(false);

  const textareaRef = useRef(null);
  const parentRef = useRef(null);
  const boardRef = useRef(null);
  const isScrolling = useIsScrolling(parentRef);

  const query = useSelector((state) => state.filterQuery);

  // Monitor Drag and Drop
  useEffect(() => {
    const element = boardRef.current;
    const monitorConfig = {
      element,
      onDrag({ location }) {
        const target = location.current.dropTargets[0];
        setHighlight(target && target.data.boardShortId === _boardId);
      },
      onDrop() {
        setHighlight(false);
      },
    };

    return monitorForElements(monitorConfig);
  }, [_boardId]);

  // Virtualizer configuration
  const virtualizer = useVirtualizer({
    horizontal: true,
    count: board.actionLists.length + 1,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 290,
  });

  // Fetch filters on component mount
  useEffect(() => {
    const fetchFilters = async () => {
      const filters = await getFilters();
      dispatch(setFilterQuery(filters));
    };
    fetchFilters();
  }, [dispatch]);


  useEffect(() => {
    const fetchBoardData = async () => {
      if (!boardId && !_boardId) return;

      setIsLoading(true);

      try {
        const result = await dispatch(fetchBoardById(boardId || _boardId)).unwrap();
        setBoard(result || { actionLists: [] });

        if (boardId) {
          dispatch(setBoardId(boardId));
        }
      } catch (error) {
        console.error('Error fetching the board:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!query && localStorage.getItem('filterQuery')) {
      dispatch(setFilterQuery(localStorage.getItem('filterQuery')));
    }

    fetchBoardData();
  }, [boardId, _boardId, dispatch]);



  // Handle adding new action list
  const handleAddList = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(`${config.API_URI}/api/actionLists`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ boardId: _boardId, title: listTitle }),
      });

      if (!response.ok) throw new Error('Failed to add action list');

      const data = await response.json();
      const newList = data.actionList;
      setBoard((prev) => ({ ...prev, actionLists: [...prev.actionLists, newList] }));
      setActionListTitle('');
      setShowForm(false);
      dispatch(showToast({ message: 'Action list added successfully!', type: 'success' }));
    } catch (error) {
      console.error('Error adding action list:', error);
      dispatch(showToast({ message: 'Error adding action list', type: 'error' }));
    }
  };

  // Handle textarea height adjustment
  useEffect(() => {
    if (textareaRef.current) {
      const resetHeight = () => {
        textareaRef.current.style.height = '20px';
        const scrollHeight = textareaRef.current.scrollHeight - 20;
        textareaRef.current.style.height = `${scrollHeight}px`;
      };

      resetHeight();
    }
  }, [listTitle]);

  // Loading state handling
  if (isLoading && !cardId && location.key === 'default') {
    return <SkeletonBoard />;
  }


  // Event handlers for form visibility
  const handleShowForm = () => {
    setShowForm(true);
    setTimeout(() => textareaRef.current?.focus(), 0);
  };

  const handleHideForm = () => setShowForm(false);
  const handleToggleOptions = (listId) => setOpenListId((prev) => (prev === listId ? null : listId));
  const handleDeleteList = (listId) => setBoard((prev) => ({
    ...prev,
    actionLists: prev.actionLists.filter((list) => list._id !== listId),
  }));

  // Styles based on dragging state
  const style = {
    ...(highlight ? { border: '2px dotted #ddd' } : {})
  };

  return (
    <>
      <Navbar board={board} setBoard={setBoard} isLoading={isLoading} />
      <div
        className={`zoobbe-workspace-board-container${isScrolling ? ' scrolling' : ''}`}
        ref={parentRef}
        style={{ width: '100%', overflowY: 'auto' }}
      >
        <div
          className='zoobbe-board'
          style={{
            // ...(highlight ? { backgroundColor: 'blue' } : { backgroundColor: 'red' }),
            width: virtualizer.getTotalSize() + virtualizer.getVirtualItems().length * 20,
            height: 'calc(-200px + 100vh)',
            position: 'relative',
          }}
          ref={boardRef}
        >
          {virtualizer.getVirtualItems().map((virtualColumn, index) => (
            <div
              className='zoobbe-action-list'
              key={board.actionLists[virtualColumn.index]?._id || `add-list-form`}
              ref={virtualizer.measureElement}
              data-index={index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 290,
                transform: `translateX(${virtualColumn.start + index * 20}px)`
              }}
            >
              {board.actionLists[virtualColumn.index] ? (
                <ActionListPND
                  number={virtualColumn.index}
                  // actionList={{ ...board.actionLists[virtualColumn.index] }} // Clone to avoid mutating but  got stack issue for this
                  actionList={board.actionLists[virtualColumn.index]}
                  isOpen={openListId === board.actionLists[virtualColumn.index]._id}
                  onToggleOptions={() => handleToggleOptions(board.actionLists[virtualColumn.index]._id)}
                  onDelete={handleDeleteList}
                  board={{ ...board }} // Clone to ensure immutability
                  setBoard={setBoard}
                  boardId={board.shortId}
                  boardScrollRef={parentRef}
                />

              ) : (
                // Render the "Add List" button and form at the end
                <div className="action-list-button-wrapper">
                  {showForm ? (
                    <form onSubmit={handleAddList} style={{ width: '100%', padding: '10px' }}>
                      <textarea
                        className="add-new-list"
                        ref={textareaRef}
                        name="add-new-list"
                        placeholder="Enter list title"
                        rows="1"
                        value={listTitle}
                        onChange={(e) => setActionListTitle(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            handleAddList(e);
                          }
                        }}
                        required
                      />
                      <div className="footer-buttons">
                        <button type="submit">Add List</button>
                        <button type="button" onClick={handleHideForm}>
                          <span className="material-symbols-outlined">close</span>
                        </button>
                      </div>
                    </form>
                  ) : (
                    <AddActionListButton onClick={handleShowForm} />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Board;
