import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Calendar from 'react-calendar';
import ZoobbeSelect from '../Global/ZoobbeSelect';

import { config } from '../../config';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { cardData } from '../../redux/Slices/cardSlice';
import { format } from 'date-fns';


import 'react-calendar/dist/Calendar.css';
import './scss/AddDueDate.scss';
import Checkbox from '../Global/Checkbox';

const AddDueDate = ({ existingDueDate, onSaveSuccess, onRemoveSuccess }) => {
    const { card } = useSelector((state) => state.card);
    const dispatch = useDispatch();
    const cardId = card.shortId;

    // State variables
    const [startDate, setStartDate] = useState(new Date());
    const [dueDate, setDueDate] = useState(new Date());
    const [dueTime, setDueTime] = useState('');
    const [startTime, setStartTime] = useState('');
    const [reminder, setReminder] = useState('None');
    const [isStartDateEnabled, setIsStartDateEnabled] = useState(false);
    const [isUpdating, setIsUpdating] = useState(!!existingDueDate);
    const [isChecked, setIsChecked] = useState(false);


    // Initialize state with card's due date if available
    useEffect(() => {
        if (card.dueDate) {
            setDueDate(card.dueDate.date ? new Date(card.dueDate.date) : new Date());
            setDueTime(card.dueDate.dueTime || '');
            setStartTime(card.dueDate.startTime || '');
            setReminder(card.dueDate.reminder || 'None');
            // setIsStartDateEnabled(!!card.dueDate.startDate);

            if (card.dueDate.startDate) {
                setStartDate(new Date(card.dueDate.startDate));
            } else {
                setStartDate(new Date());
            }
        }
    }, [card.dueDate]);

    // Set default time if not provided
    useEffect(() => {
        if (!dueTime) {
            const now = new Date();
            let hours = now.getHours();
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const period = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            setDueTime(`${hours}:${minutes} ${period}`);
        }
    }, [dueTime]);

    useEffect(() => {
        if (!startTime) {
            const now = new Date();
            let hours = now.getHours();
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const period = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            setStartTime(`${hours}:${minutes} ${period}`);
        }
    }, [startTime]);

    // Update startDate when isStartDateEnabled or dueDate changes
    useEffect(() => {
        if (isStartDateEnabled && dueDate) {
            setStartDate(dueDate);
        } else if (!isStartDateEnabled) {
            setStartDate(new Date());
        }
    }, [isStartDateEnabled, dueDate]);

    // Handle date selection from Calendar
    const handleDateChange = (date) => {
        // Normalize the date to midnight to prevent time zone issues

        const normalizedDate = new Date(date);
        setDueDate(normalizedDate);

        console.log(`Date: ${normalizedDate}`);

    };

    // Save due date and status to the backend
    const handleSave = async () => {
        const dueDateData = {
            date: dueDate,
            dueTime,
            startDate,
            startTime,
            reminder,
            status: "In-Progress" // Or handle logic for completed/overdue status
        };

        try {
            const token = localStorage.getItem('accessToken');
            const method = isUpdating ? 'PUT' : 'POST';
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/duedate`, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(dueDateData),
            });

            if (response.ok) {
                const updatedCard = await response.json();
                dispatch(cardData(updatedCard));
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
                if (onSaveSuccess) onSaveSuccess(updatedCard);
            } else {
                console.error('Failed to save due date');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Remove due date from the backend
    const handleRemove = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/duedate`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                const updatedCard = await response.json();
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
                dispatch(cardData(updatedCard));
                if (onRemoveSuccess) onRemoveSuccess(updatedCard);
            } else {
                console.error('Failed to remove due date');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const reminderOptions = [
        { index: 0, value: 'none', label: 'None' },
        { index: 1, value: '5-minutes-before', label: '5 Minutes Before' },
        { index: 2, value: '10-minutes-before', label: '10 Minutes Before' },
        { index: 3, value: '30-minutes-before', label: '30 Minutes Before' },
        { index: 4, value: '1-hour-before', label: '1 Hour Before' },
        { index: 5, value: '1-day-before', label: '1 Day Before' },
    ];

    const reminderIndex = reminderOptions.find(option => option.value === card.dueDate.reminder)?.index;
    const [defaultSelectedOption, setDefaultSelectedOption] = useState(reminderIndex || 0);

    const handleReminderSelect = (type) => {
        setReminder(type.value);
        setDefaultSelectedOption(type.index);
    };

    return (
        <div className="add-due-date-popup">
            <div className="add-due-date-popup-header">
                <h3>Dates</h3>
            </div>

            <Calendar
                value={dueDate}
                onChange={handleDateChange}
                className="calendar-grid"
            />

            <div className="date-inputs">
                <div className="input-group">
                    <div className="start-date-checkbox">
                        <Checkbox
                            checked={isStartDateEnabled}
                            onChange={(checked) => setIsStartDateEnabled(checked)}
                        />
                        <label htmlFor="start-date-checkbox">Start date</label>
                    </div>

                    <div className="due-date-field">
                        <input
                            type="text"
                            value={startDate ? format(startDate, 'YYY-MM-dd') : ''}
                            onChange={(e) => setStartDate(new Date(e.target.value))}
                            disabled={!isStartDateEnabled}
                        />
                        <input
                            type="text"
                            value={startTime}
                            onChange={(e) => setDueTime(e.target.value)}
                            disabled={!isStartDateEnabled}
                        />
                    </div>
                </div>
                <div className="input-group">
                    <div className="due-date-checkbox">

                        <Checkbox
                            id="due-date-checkbox"
                            checked={!!dueDate}
                            onChange={(checked) => setIsStartDateEnabled(checked)}
                            readOnly
                        />

                        <label htmlFor="due-date-checkbox">Due date</label>
                    </div>

                    <div className="due-date-field">
                        <input
                            type="text"
                            value={dueDate ? format(dueDate, 'YYY-MM-dd') : ''}
                            onChange={(e) => setDueDate(new Date(e.target.value))}
                        />
                        <input
                            type="text"
                            value={dueDate ? dueTime : ''}
                            onChange={(e) => setDueTime(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="reminder">
                <label htmlFor="reminder-select">Set due date reminder</label>
                <ZoobbeSelect
                    options={reminderOptions}
                    defaultSelectedOption={defaultSelectedOption}
                    onSelect={handleReminderSelect}
                />

                <p>Reminders will be sent to all members and watchers of this card.</p>
            </div>
            <div className="actions">
                <button className="save-btn" onClick={handleSave}>Save</button>
                <button className="remove-btn" onClick={handleRemove}>Remove</button>
            </div>
        </div>
    );
};

export default AddDueDate;
