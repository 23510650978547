import React, { useState } from 'react';
import './scss/Checkbox.scss';

const Checkbox = ({ checked, onChange }) => {

    return (
        <div className="checkbox-container" onClick={() => onChange(!checked)}>
            <input
                type="checkbox"
                id="checkbox"
                checked={checked}
                onChange={() => onChange(!checked)}
            />
            <div className={`checkbox ${checked ? 'checked' : ''}`}>
                {checked && (
                    <span className="material-symbols-outlined check-mark">
                        check
                    </span>
                )}
            </div>
        </div>
    );
};

export default Checkbox;
