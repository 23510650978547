import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { togglePopover } from '../redux/Slices/popoverSlice';

const useOutsideClick = (ref, onClickOutside) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // Ensure the click outside handler doesn't affect the current open popover
        if (event.target.closest('[data-popover-trigger]')) {
          // If click target is a popover trigger, don't close the popover
          return;
        }
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside, dispatch]);
};

export default useOutsideClick;
