import React from "react";
import { Link } from "react-router-dom";
import { find, toSlug } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/Slices/modalSlice";

import './scss/BaordSection.scss'
import MemberImage from "../Global/MemberImage";

const RecentView = () => {

    const dispatch = useDispatch();

    const { workspaces } = useSelector(state => state.workspaces);
    const recentlyViewed = find.get(workspaces, { name: 'recentViewedBoards', limit: 3 });


    return (
        <div className="boards-page-board-section-list">
            {recentlyViewed?.map(board => {
                const members = find.get(workspaces, { name: 'members', board: board._id });
                const cards = find.get(workspaces, { name: 'cards', board: board._id });
                // const wCards = find.get(workspaces, { name: 'cards', workspace: _id });

                return (
                    <div className="boards-page-board-section-list-item" key={board._id}>
                        <div className="board-item">
                            <div className="board-title"> {board.title}</div>
                            <div className="board-details">
                                <div className="board-members">
                                    <MemberImage members={members} size={30} />
                                    {
                                        members.length > 3 && (
                                            <span className="member-count">+{members.length - 3}</span>
                                        )
                                    }
                                </div>
                                <div className="board-stats">
                                    <span className="material-symbols-outlined">
                                        credit_card
                                    </span>
                                    <span>{cards.length} </span>
                                    {/* / {wCards.length} */}
                                </div>
                            </div>
                        </div>
                        <Link className="board-tile-link" to={board.permalink}></Link>
                    </div>
                )
            })}
        </div>
    )
}

export default RecentView;