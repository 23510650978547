import React from 'react';
// import './scss/index.scss';

const AddCardButton = ({ onClick }) => {
    return (
        <div className="card-button-container">
            <button className="add-card-button" onClick={onClick}>
                <span className="material-symbols-outlined">
                    add
                </span>
                Add a card
            </button>
            <span className='card-icon'>
                <span className="material-symbols-outlined">
                    credit_card
                </span>
            </span>
        </div>
    );
}

export default AddCardButton;
