// src/redux/Slices/filterQuerySlice.js

import { createSlice } from '@reduxjs/toolkit';

const filterQuerySlice = createSlice({
    name: 'filterQuery',
    initialState: {
        keyword: '',
        noMembers: false,
        selectMembers: false,
        assignedToMe: false,
        noDates: false,
        overdue: false,
        dueNextDay: false,
        noLabels: false,
        selectedMembers: [],
        selectedLabels: [],
        filteredStatus: false,
    },
    reducers: {
        setFilterQuery: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setFilterQuery } = filterQuerySlice.actions;
export default filterQuerySlice.reducer;
