export const ActionTypes = {
    MEMBER_ADDED: 'MEMBER_ADDED',
    MEMBER_REMOVED: 'MEMBER_REMOVED',
    ROLE_CHANGED: 'ROLE_CHANGED',
    WORKSPACE_CREATED: 'WORKSPACE_CREATED',
    WORKSPACE_UPDATED: 'WORKSPACE_UPDATED',
    WORKSPACE_DELETED: 'WORKSPACE_DELETED',
    BOARD_CREATED: 'BOARD_CREATED',
    BOARD_UPDATED: 'BOARD_UPDATED',
    BOARD_DELETED: 'BOARD_DELETED',
    ACTIONLIST_CREATED: 'ACTIONLIST_CREATED',
    ACTIONLIST_UPDATED: 'ACTIONLIST_UPDATED',
    ACTIONLIST_DELETED: 'ACTIONLIST_DELETED',
    CARD_CREATED: 'CARD_CREATED',
    CARD_UPDATED: 'CARD_UPDATED',
    CARD_DELETED: 'CARD_DELETED',
};
