import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import ZoobbeSelect from '../Global/ZoobbeSelect';


import './scss/CreateWorkspace.scss';

import { useNavigate } from 'react-router-dom';
import { toSlug } from '../../utils/helpers';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { closeModal } from '../../redux/Slices/modalSlice';
import { config } from '../../config';

const CreateBoard = ({ defaultSelectedOption }) => {
  const { workspaces, status, error } = useSelector(state => state.workspaces);

  const navigate = useNavigate();

  const [boardTitle, setBoardTitle] = useState('');

  const workspaceOptions = workspaces?.map(workspace => ({
    value: workspace._id,
    label: workspace.name
  }));



  const visibilityOptions = [
    { value: 'workspace', label: 'Workspace' },
    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Private' },
  ];

  const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceOptions[defaultSelectedOption]);
  const [selectedVisibility, setSelectedVisibility] = useState(visibilityOptions[0]);


  console.log(selectedWorkspace);

  console.log(workspaces);

  const dispatch = useDispatch();


  const handleCreateBoard = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(config.API_URI  + `/api/boards`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          workspaceId: selectedWorkspace.value,
          title: boardTitle,
        }),
      });

      if (!response.ok) {
        dispatch(showToast({ message: 'Failed to create board', type: 'error' }));
        throw new Error('Failed to create board.');
      }
      const data = await response.json();
      dispatch(showToast({ message: data.message, type: 'success' }));
      dispatch(closeModal());
      dispatch(fetchWorkspaces());
      
      <navigate to={`/b/${data.board._id}/${toSlug(data.board.title)}`} />

    } catch (error) {
      dispatch(showToast({ message: 'Error creating board:' + error, type: 'error' }));

    }
  };


  const handleWorkspaceSelect = (workspace) => {
    setSelectedWorkspace(workspace);
    console.log('Selected Workspace:', workspace);
  };

  const handleVisibilitySelect = (visibility) => {
    setSelectedVisibility(visibility);
    console.log('Selected visibility:', visibility);
  };

  return (
    <div className="create-board-form">
      <h1>Create Board</h1>
      <form onSubmit={handleCreateBoard}>
        <label htmlFor="workspace-name">Board Title*</label>
        <input
          type="text"
          id="workspace-name"
          value={boardTitle}
          onChange={(e) => setBoardTitle(e.target.value)}
          placeholder="Zoobbe Develoment"
        />

        <label htmlFor="workspace-type">Workspace</label>
        <ZoobbeSelect
          options={workspaceOptions}
          defaultSelectedOption={defaultSelectedOption}
          onSelect={handleWorkspaceSelect}
        />

        <label htmlFor="workspace-type">Visibility</label>
        <ZoobbeSelect
          options={visibilityOptions}
          defaultSelectedOption={0}
          onSelect={handleVisibilitySelect}
        />

        <button type="submit">Create</button>
      </form>
    </div>
  );
};

export default CreateBoard;
