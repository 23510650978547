import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ZoobbeSelect from '../Global/ZoobbeSelect';

import './scss/Attachment.scss';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { config } from '../../config';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import SearchSelect from '../Global/SearchSelect';
import { uploadAttachment } from '../../redux/Slices/attachmentSlice';

const Attachment = () => {
    const [title, setTitle] = useState('Attachment');
    const [selectedAttachment, setSelectedAttachment] = useState(null);

    const inputRef = useRef(null);
    const fileInputRef = useRef(null);

    const { card } = useSelector((state) => state.card);
    const cardId = card.shortId;


    const dispatch = useDispatch();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, []);

    const attachmentsOptions = [
        { value: 'none', label: '(none)' },
    ];

    const handleAttachmentSelect = (attachments) => {
        setSelectedAttachment(attachments);
        console.log('Selected Attachment:', attachments);
    };


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            dispatch(uploadAttachment({ file, cardId }));
        }
    };


    return (
        <div className="add-attachment">
            <h2>Attach</h2>

            <div className="group">
                <h3>Attach a file from your computer</h3>
                <p>Click the 'Choose a file' button to browse and select a file from your computer.</p>
                <button onClick={() => fileInputRef.current.click()} className='attach-file-btn'>Choose a file</button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
            </div>

        </div>
    );
};

export default Attachment;
