import { createSlice } from '@reduxjs/toolkit';

const toastSlice = createSlice({
    name: 'toast',
    initialState: null,
    reducers: {
        showToast: (state, action) => {
            return { message: action.payload.message, type: action.payload.type };
        },
        hideToast: () => {
            return null;
        },
    },
});

export const { showToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
