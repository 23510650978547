import React, { useEffect, } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import Comment from './Comment';

import './scss/Activities.scss';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';



const Activities = ({ cardId }) => {

    const dispatch = useDispatch();
    const { activities, loading, error } = useSelector((state) => state.activities);

    useEffect(() => {
        dispatch(fetchActivities({cardId}));
    }, [dispatch, cardId]);

    return (
        <div className="activities">
            {activities?.map((activity) => (
                activity.actionType === 'ADDED_COMMENT' ? (
                    <Comment key={activity._id} activity={activity} cardId={cardId} />
                ) : (
                    <div key={activity._id} className="activity">
                        {activity.initiator?.profilePicture ? (
                            <img
                                src={activity.initiator.profilePicture}
                                alt={activity.initiator.name}
                                className="activity__profile-img"
                            />
                        ) : (
                            <ImagePlaceholder text={activity.initiator.name} />
                        )}

                        <div className="activity__content">
                            <div className="activity__header">
                                <strong>{activity.initiator.name}</strong>
                                <div className="activity__details" dangerouslySetInnerHTML={{ __html: activity.details }} />
                            </div>

                            <span>
                                {new Date(activity.createdAt).toLocaleString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric', // Added seconds here
                                    hour12: true
                                })}

                            </span>
                        </div>
                    </div>
                )
            ))}
        </div>
    );
};

export default Activities;
