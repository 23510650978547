import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePopoverPosition } from '../redux/Slices/popoverSlice';
import { getScrollableAncestor } from '../utils/helpers';

const usePopoverPositionUpdater = (target, popoverRef) => {
    const dispatch = useDispatch();

    const updatePosition = () => {
        if (!target || !popoverRef.current) return;

        const rect = target.getBoundingClientRect();
        const popoverRect = popoverRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let top = rect.bottom + window.scrollY + 4;
        let left = rect.left + window.scrollX;

        // Adjust position if out of viewport bounds
        if (left + popoverRect.width > viewportWidth) {
            left = rect.right - popoverRect.width + window.scrollX;
        }
        if (top + popoverRect.height > viewportHeight) {
            top = rect.top - popoverRect.height + window.scrollY - 4;
        }
        if (left < 0) {
            left = rect.left + window.scrollX;
        }
        if (top < 0) {
            top = rect.bottom + window.scrollY;
        }

        // Final adjustments to prevent overflow
        if (left + popoverRect.width > viewportWidth) {
            left = viewportWidth - popoverRect.width;
        }
        if (top + popoverRect.height > viewportHeight) {
            top = viewportHeight - popoverRect.height;
        }
        if (left < 0) {
            left = 0;
        }
        if (top < 0) {
            top = 0;
        }

        dispatch(updatePopoverPosition({ top, left }));
    };

    useEffect(() => {
        if (!target) return;

        const scrollableAncestor = getScrollableAncestor(target);

        // Update position immediately on mount
        updatePosition();

        // Event listeners for scroll and resize
        const handleScroll = updatePosition;
        const handleResize = updatePosition;

        scrollableAncestor.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            scrollableAncestor.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch, target, popoverRef]);

    // Optional: If you want to handle changes to target or popoverRef separately
    useEffect(() => {
        updatePosition();
    }, [target, popoverRef]);
};

export default usePopoverPositionUpdater;
