import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async thunk to fetch an action list by ID
export const fetchActionListById = createAsyncThunk(
    'actionList/fetchActionListById',
    async (actionListId, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/actionLists/${actionListId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch action list');
        }

        const data = await response.json();
        return data.actionList;
    }
);

const actionListSlice = createSlice({
    name: 'actionList',
    initialState: {
        actionList: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        // You can add other synchronous reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActionListById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchActionListById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.actionList = action.payload;
            })
            .addCase(fetchActionListById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default actionListSlice.reducer;
